'use client'
import React, { forwardRef, RefObject, Suspense, useEffect } from 'react'
import Main from 'components/Layouts/Backend/layout/Main'


import Script from "next/script"
import { DialogProvider } from '@aw/dialog'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { Toaster } from 'react-hot-toast'
import { Provider as ReduxProvider } from 'react-redux'
import ErrorBoundary from 'components/Misc/ErrorBoundary'
import Swr from 'components/Misc/Swr'
import { WorkSpaceProvider } from 'context/workSpaceContext'
import { VideoPlayerContextProvider } from 'components/videoPlayerPopup/context'
import store from 'redux/store'

import { AuthProvider } from "@/modules/auth/context"
import { ThemeProvider } from "@/context/ThemeContext"
import { Nunito, Montserrat, Karla, Open_Sans } from 'next/font/google'

import './globals.css'
import { ConfigProvider } from "antd"
import { EmailAIProvider } from "@/context/ai/email"

export const nunito = Nunito({
  subsets: ['latin'],
  variable: '--font-nunito',
  display: 'swap'
})

export const montserrat = Montserrat({
  subsets: ['latin'],
  variable: '--font-montserrat',
  display: 'swap'
})

export const karla = Karla({
  subsets: ['latin'],
  variable: '--font-karla',
  display: 'swap',
  preload: true
})

export const OpenSans = Open_Sans({
  subsets: ['latin'],
  variable: '--font-open-sans',
  display: 'swap'
})

export type AppLayoutProps = {
  breadcrumb?: () => JSX.Element
  children?: React.ReactNode
}

// eslint-disable-next-line react/display-name
const AppLayout = forwardRef<RefObject<HTMLElement>, AppLayoutProps>(({ children }, ref) => {

  // TODO - use screen hook to detect small device & add body class - overflow-hidden
  const refProp = ref ? { ref: ref } : {}
  return (
    <html className={`${karla.variable} ${montserrat.variable} ${OpenSans.variable}`}>
      <Script src="https://accounts.google.com/gsi/client"></Script>
      <body className="antialiased" >
        <Suspense>
          <ErrorBoundary>
            <ThemeProvider>
              <ReduxProvider store={store}>
                <VideoPlayerContextProvider>
                  <WorkSpaceProvider>
                    <DialogProvider>
                      <AuthProvider>
                        <EmailAIProvider>

                          <Swr>
                            <Toaster position="bottom-center" />
                            <ConfigProvider getPopupContainer={triggerNode => triggerNode?.parentNode}>
                              {children}
                              {/* <Main {...props} ref={ref} /> */}
                            </ConfigProvider>
                          </Swr>
                        </EmailAIProvider>
                      </AuthProvider>
                    </DialogProvider>

                  </WorkSpaceProvider>
                </VideoPlayerContextProvider>
              </ReduxProvider>
            </ThemeProvider>
          </ErrorBoundary >
        </Suspense>
      </body >
    </html >
  )
})

export default AppLayout