import React, { useState, useEffect, useCallback } from 'react';
import { Select } from 'antd';
import { PulseLoader } from 'react-spinners';

type FetchItemsResponse<T> = {
    data: T[];
    links?: {
        next?: string;
    };
};

type CustomDropdownProps<T> = {
    value: string | string[];
    onChange: (value: string | string[]) => void;
    fetchItems: (page: number, pageSize: number, search?: string) => Promise<FetchItemsResponse<T>>;
    mapResponseToOptions: (items: T[]) => { label: string; value: string }[];
    pageSize?: number;
    placeholder?: string;
    searchEnabled?: boolean;
    [key: string]: any;
};

function CustomDropdown<T>({
    value,
    onChange,
    fetchItems,
    mapResponseToOptions,
    pageSize = 100,
    placeholder = 'Select or search',
    searchEnabled = true,
    ...props
}: CustomDropdownProps<T>) {
    const [items, setItems] = useState<T[]>([]);
    const [hasMore, setHasMore] = useState(false);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);

    // Debounce function to prevent multiple rapid API calls
    const debounce = (func: (...args: any[]) => void, delay: number) => {
        let debounceTimer: NodeJS.Timeout;
        return function (...args: any[]) {
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => func(...args), delay);
        };
    };

    const fetchData = useCallback(
        (reset = false, search = '') => {
            setLoading(true);
            fetchItems(page, pageSize, search)
                .then((res) => {
                    setHasMore(Boolean(res?.links?.next));
                    setItems((prev) => (reset ? res.data : [...prev, ...res.data])); // Append new data or reset
                })
                .catch((err) => console.error(err))
                .finally(() => setLoading(false));
        },
        [fetchItems, page, pageSize]
    );



    useEffect(() => {
        fetchData();
    }, [page]);

    const debouncedFetchData = useCallback(debounce(fetchData, 1000), [fetchData]);

    const handleSearch = (search: string) => {
        if (page !== 1) {
            setPage(1); // Reset to page 1 on new search
        }
        debouncedFetchData(true, search);
    };

    const handlePopupScroll = useCallback(
        debounce((e: React.UIEvent<HTMLDivElement>) => {
            const target = e.target as HTMLDivElement;
            if (target.scrollTop + target.offsetHeight >= target.scrollHeight - 10 && hasMore && !loading) {
                setPage((prev) => prev + 1);
            }
        }, 300),
        [hasMore, loading]
    );

    return (
        <div>
            <Select
                showSearch={searchEnabled}
                className="w-full"
                placeholder={placeholder}
                dropdownRender={(menu) => (
                    <div>
                        {menu}
                        <div className="flex w-full justify-center">
                            {loading && <PulseLoader color="rgb(66 94 189)" size={4} />}
                        </div>
                    </div>
                )}
                onChange={onChange}
                size="large"
                value={value}
                {...props}
                optionFilterProp="label"
                onSearch={searchEnabled ? handleSearch : undefined}
                autoClearSearchValue
                options={mapResponseToOptions(items)}
                onPopupScroll={handlePopupScroll}
            />
        </div>
    );
}

export default CustomDropdown;
