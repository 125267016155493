'use client'
import React, { useState, FormEvent, useEffect, useRef } from 'react';
import Markdown from 'react-markdown'
import Button from "@/components/Common/Button";
import { Input } from "@aw/design-system";
import { Chat, ChatType } from "../../create-job-form";
import { useAuth } from "@/hooks/auth";
import toast from "react-hot-toast";
import { Trash } from "@aw/icons";
import { ArrowDown } from "phosphor-react";
interface ChatComponentProps {
    currentChat: Chat | null;
    updateChat: ({ userMessage, type }: { userMessage: string; type: ChatType }) => void;
    addDescriptionHandler: (id: number) => void;
    loading: boolean;
    onDeleteChat: () => void;
    type: ChatType;
}

const ChatComponent: React.FC<ChatComponentProps> = ({ currentChat, updateChat, addDescriptionHandler, loading, onDeleteChat, type }) => {
    const [inputMessage, setInputMessage] = useState<string>('');
    const { user } = useAuth({ middleware: ['auth'] })
    const chatContentRef = useRef<HTMLDivElement | null>(null);
    const [showScrollButton, setShowScrollButton] = useState(true);
    const minMessageLength = 200;

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (!inputMessage.trim()) {
            toast.error('Message cannot be empty');
            return;
        }
        if (updateChat) { updateChat({ userMessage: inputMessage, type }) };
        setInputMessage('');
    };

    // scroll to the bottom when the button is clicked
    const scrollToBottom = () => {
        const chatContainer = chatContentRef.current;
        if (chatContainer) {
            chatContainer.scrollTo({
                top: chatContainer.scrollHeight,
                behavior: "smooth",
            });
        }
        setShowScrollButton(false);
    }

    const handleScroll = () => {
        const chatContainer = chatContentRef.current;
        if (chatContainer) {
            const tolerance = 5; // Allow for a small margin of error
            const isBottom =
                Math.abs(
                    chatContainer.scrollHeight -
                    chatContainer.scrollTop -
                    chatContainer.clientHeight
                ) <= tolerance;
            setShowScrollButton(!isBottom);
        }
    };


    useEffect(() => {
        if (chatContentRef.current) {
            scrollToBottom();
        }
    }, []);

    const userBoxCss = ' max-w-[42rem] rounded-tr';
    const botBoxCss = 'max-w-[42rem] rounded-tl';

    return (
        <div className="h-[70vh] w-full flex flex-col items-stretch justify-between">
            <div className="overflow-y-auto w-full pl-6 scrollbar pr-1 pb-2" ref={chatContentRef} onScroll={handleScroll}>
                {currentChat && (
                    currentChat.messages.map((message) => (
                        <div key={message.id} className={`w-full flex ${type === 'GEN' ? (message.sender === 'user' ? 'justify-end' : 'justify-start') : (message.sender === 'user' ? 'justify-start' : 'justify-end')}`}>
                            <div className={`flex items-start gap-2 ${type === 'GEN' ? "flex-row" : "flex-row-reverse"}`}>
                                {message.sender === 'user' ? <ChatUserIcon imageUrl={user?.imageUrl || ''} /> : <BotIcon />}
                                <div

                                    className={` mb-2 px-6 py-4  ${type == 'GEN' ? (message.sender === 'user' ? userBoxCss + ' bg-neutralgray-3' : botBoxCss + ' bg-neutralgray-2') : (message.sender === 'user' ? botBoxCss + ' bg-neutralgray-3' : userBoxCss + ' bg-neutralgray-2')} rounded-[2rem] `}
                                >

                                    <Markdown className={'leading-7'}>{message.message.replace(/\\n/g, "\n")}</Markdown>
                                    {
                                        (message.sender === 'bot' && (message?.showInsertButton)) && <div className="flex justify-end w-full relative">
                                            {(message.message.length > minMessageLength) && <button
                                                className="text-primary-500 cursor-pointer bg-transparent border-2 border-primary-500 text-xs py-1 px-3 rounded-full hover:bg-primary-500 hover:text-white transition duration-300 ease-in-out transform hover:scale-95"
                                                onClick={() => addDescriptionHandler(message.id)}
                                                title="Insert description">
                                                Insert
                                            </button>}
                                        </div>
                                    }
                                    {/* <span className="text-xs text-gray-600">
                                        {new Date(message.timestamp).toLocaleTimeString()}
                                    </span> */}
                                </div>
                            </div>
                        </div>

                    ))
                )}
            </div>
            {showScrollButton && <button className="absolute bottom-20 left-1/2 rounded-full shadow-md  w-fit p-1 bg-white" onClick={scrollToBottom}>
                <ArrowDown className=" h-5 w-5 text-primary-800" />
            </button>}


            {/* Input field and send button */}
            <form onSubmit={handleSubmit} className="flex items-center w-full px-6 gap-2">
                <Input
                    className="flex-1 h-[2.5rem] rounded-full"
                    placeholder="Type a message..."
                    value={inputMessage}
                    onChange={(e) => setInputMessage(e.target.value)}
                />
                <Button color='primary'
                    type='submit'
                    disabled={loading}
                    size='md'
                    className='!rounded-full'
                >
                    Send
                </Button>
                <div title="Start new chat" className={`h-8 w-8 rounded-full flex items-center justify-center bg-error-50 cursor-pointer ${loading ? 'pointer-events-none opacity-50' : ''}`}
                    onClick={onDeleteChat}>

                    <Trash className="h-4 w-4 !fill-error-500" />
                </div>
            </form>

        </div>
    );
};


const ChatUserIcon = ({ imageUrl }: { imageUrl: string }) => {
    return (
        <div className='flex items-center justify-center h-6 w-6 order-2 border border-neutralgray-10 rounded-full'>
            {/* <User className="w-4 h-4" /> */}
            <img src={imageUrl} className="w-4 h-4 rounded-full " />

        </div>
    )
}


const BotIcon = () => {
    return (
        <div className='flex items-center justify-center h-8 w-8 bg-white border border-neutralgray-3 rounded-full'>
            <img src='/star.svg' className="w-6 h-6 rounded-full " />

        </div>
    )
}

export default ChatComponent;
