import React from 'react'

type Props = {
    className?: string
}

function Trash({ className = 'fill-[#2E354A]' }: Props) {
    return (

        <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg"
            className={`fill-neutralgray-10 ${className}`}
        >
            <path d="M17.125 2.5C17.4688 2.5 17.75 2.77969 17.75 3.125C17.75 3.47031 17.4688 3.75 17.125 3.75H16.4102L15.4141 17.6797C15.3242 18.9883 14.1992 20 12.9219 20H5.07812C3.76602 20 2.67773 18.9883 2.58398 17.6797L1.58945 3.75H0.875C0.529844 3.75 0.25 3.47031 0.25 3.125C0.25 2.77969 0.529844 2.5 0.875 2.5H4.58984L5.60156 0.88125C5.94531 0.333047 6.54688 0 7.19141 0H10.8086C11.4531 0 12.0547 0.333047 12.3633 0.88125L13.4102 2.5H17.125ZM7.19141 1.25C6.97656 1.25 6.77734 1.36094 6.66406 1.54375L6.06641 2.5H11.9336L11.3359 1.54375C11.2227 1.36094 11.0234 1.25 10.8086 1.25H7.19141ZM15.1562 3.75H2.84258L3.83086 17.5898C3.87773 18.2422 4.42188 18.75 5.07812 18.75H12.9219C13.5781 18.75 14.1211 18.2422 14.168 17.5898L15.1562 3.75Z" />
        </svg>

    )
}

export default Trash