import React from 'react'
import Dialog from './Dialog'
import { DialogContext } from './dialogContext'
import useHideDialog from './hooks/useHideDialog'
import useKeyPress from './hooks/useKeyPress'
import useOnClickOutside from './hooks/useOnClickOutside'

const transitions = Object.freeze({
    grow: {
        enterFrom: 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95',
        enterTo: 'opacity-100 translate-y-0 sm:scale-100',
        leave: 'ease-in duration-200',
        leaveFrom: 'opacity-100 translate-y-0 sm:scale-100',
        leaveTo: 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95',
    },
    tilt: {
        enterFrom: 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95',
        enterTo: 'opacity-100 translate-y-0 sm:scale-100 animate-tilt-in-fwd-tr',
        leave: 'ease-in duration-200',
        leaveFrom: 'opacity-100 translate-y-0 sm:scale-100',
        leaveTo: 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95',
    },
    slide: {
        enterFrom: 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95',
        enterTo: 'opacity-100 translate-y-0 sm:scale-100 animate-slide-in-elliptic-top-fwd',
        leave: 'ease-in duration-200',
        leaveFrom: 'opacity-100 translate-y-0 sm:scale-100',
        leaveTo: 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95',
    },
    pulse: {
        enterFrom: 'opacity-0 translate-y-1',
        enterTo: 'opacity-100 -translate-y-5',
        leave: 'ease-in duration-200',
        leaveFrom: 'opacity-100',
        leaveTo: 'opacity-0 translate-y-5 sm:translate-y-0',
    },
    fade: {
        enterFrom: 'opacity-0 scale-105',
        enterTo: 'opacity-100 scale-100',
        leave: 'ease-in duration-200',
        leaveFrom: 'opacity-100 scale-100',
        leaveTo: 'opacity-0 scale-105',
    },
})

const sizes = Object.freeze({
    xs: 'max-w-xs',
    sm: 'max-w-sm',
    md: 'max-w-md',
    lg: 'max-w-lg',
    xl: 'max-w-xl',
    '2xl': 'max-w-2xl',
    '3xl': 'max-w-3xl',
    '4xl': 'max-w-4xl',
    '5xl': 'max-w-5xl',
    '6xl': 'max-w-6xl',
    '7xl': 'max-w-7xl',
})

type Props = {
    id: string
    children: (ref: React.MutableRefObject<any>) => JSX.Element
    drawer?: boolean
    className?: string
    transition?: keyof typeof transitions
    size?: keyof typeof sizes
    hideOnEsc?: boolean
    hideOnClickOutside?: boolean
    onOpen?: () => {}
    onClose?: () => {}
    allowOverflow?: boolean
}

function Modal({
    id,
    children,
    onOpen,
    onClose,
    drawer = true,
    hideOnClickOutside = true,
    hideOnEsc = true,
    size = 'lg',
    allowOverflow = false,
    transition = 'grow',
    className,
}: Props) {
    const dialogContentRef = React.useRef(null)
    const { ids: dialogIds } = React.useContext(DialogContext)
    const escPress = useKeyPress('Escape')

    const [active, setActive] = React.useState(false)
    const [show, setShow] = React.useState(false)
    const removeFromDialogsList = useHideDialog(id)
    const hide = () => {
        // setActive(false)
        removeFromDialogsList()
    }

    const isLast = React.useMemo(() => dialogIds.at(-1) === id, [dialogIds, id])

    /**auto show/hide dialog if present in the context dialog ids state*/
    React.useLayoutEffect(() => {
        if (!dialogIds.includes(id)) {
            setShow(false)
            return
        }

        if (dialogIds.includes(id)) {
            setShow(true)
        }
    }, [dialogIds, id])

    /** run lifecycle callbacks */
    React.useEffect(() => {
        if (show) {
            onOpen?.()
            setActive(true)
        }
    }, [show, onOpen])

    React.useEffect(() => {
        // effective for escape click / outside click
        if (!dialogIds.includes(id) && active) {
            onClose?.()
            setActive(false)
        }
    }, [dialogIds, id, active, onClose])
    /** run lifecycle callbacks */

    /** hide the modal if esc pressed */
    React.useEffect(() => {
        if (!escPress || !show || !hideOnEsc || !isLast) return

        setShow(false)
    }, [escPress, show, isLast])

    /** if just made hidden then remove from context dialogs list */
    React.useEffect(() => {
        if (show) return

        const timer = setTimeout(() => {
            hide()
        }, 200)

        return () => {
            clearTimeout(timer)
        }
    }, [show])

    /** hide the modal if outside clicked */
    useOnClickOutside(dialogContentRef, () => {
        if (show && hideOnClickOutside && isLast) hide()
    })

    return (
        <Dialog ref={dialogContentRef} open={show} size={size} transition={transition} isDrawer={drawer} className={className} allowOverflow={allowOverflow}>
            {children}
        </Dialog>
    )
}

export default Modal
