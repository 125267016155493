import { WorkSpaceContext } from "@/context/workSpaceContext";
import { getCookie } from "@/lib/axios";
import { ASSISTANT_NAME, ChatType } from "@/modules/organization/jobs/listings/create-job-form"
import { fetcher } from "@aw/lib";
import { createContext, useContext, useState } from 'react';
import toast from "react-hot-toast";
import TurndownService from 'turndown';

type Message = {
    id: number;
    timestamp: number;
    sender: 'bot' | 'user';
    message: string;
    showInsertButton?: boolean;
}

type Chat = {
    id: string;
    title: string;
    messages: Message[];
}

type AIServerStatus = {
    loading: boolean;
    success: boolean;
}

type ImprovedMessageStateType = {
    loading: boolean;
    message: null | string;
    showInsertButton?: boolean;
}

type AIContextType = {
    currentChat: Chat | null;
    chatLoading: boolean;
    AIServerStatus: AIServerStatus;
    setAIServerStatus: any;
    setCurrentChat: any;
    setChatLoading: any;
    checkHealth: () => Promise<void>;
    addNewChat: (params: { role: string; requirements: string; additional_context: string }) => Promise<void>;
    updateChat: (params: { userMessage: string, type: ChatType }) => Promise<void>;
    improveMessage: (params: { userMessage: string, modifications: string }) => Promise<void>;
    improvedMessageState: ImprovedMessageStateType;
    addNewImproveChat: (params: { existing_description: string, modifications: string }) => Promise<void>;
    currentImproveChat: any;
    setCurrentImproveChat: any;
}

const defaultAIContext: AIContextType = {
    currentChat: null,
    chatLoading: false,
    AIServerStatus: { loading: false, success: false },
    setCurrentChat: () => { },
    setChatLoading: () => { },
    setAIServerStatus: () => { },
    checkHealth: async () => { },
    addNewChat: async () => { },
    updateChat: async () => { },
    improveMessage: async () => { },
    improvedMessageState: { loading: false, message: null },
    addNewImproveChat: async () => { },
    currentImproveChat: null,
    setCurrentImproveChat: () => { },
};

const AIContext = createContext(defaultAIContext);

function stripHtmlTags(str: string) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(str, 'text/html');
    return doc.body.textContent || "";
}

const turndownService = new TurndownService();

export const JobsAIProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [currentChat, setCurrentChat] = useState<Chat | null>(null);
    const [chatLoading, setChatLoading] = useState(false);
    const [AIServerStatus, setAIServerStatus] = useState({
        loading: false,
        success: false,
    });
    const [improvedMessageState, setImprovedMessageState] = useState<ImprovedMessageStateType>({
        loading: false,
        message: null,
        showInsertButton: false,
    });

    const { currentOrganization } = useContext(WorkSpaceContext)

    const checkHealth = async () => {
        if (!AIServerStatus.loading) {
            setAIServerStatus({
                loading: true,
                success: false,
            });
        }
        fetcher(`/organization/${currentOrganization}/ai/health`).then(() => {
            setAIServerStatus({
                loading: false,
                success: true,
            });
        }).catch(() => {
            setAIServerStatus({
                loading: false,
                success: false,
            });
        })
    }

    const addNewChat = async ({ role, requirements, additional_context }: {
        role: string;
        requirements: string;
        additional_context: string;
    }) => {

        setChatLoading(true)
        let data = {}
        if (Object.keys(data).length > 0) {
            data = {
                role,
                requirements,
                additional_context
            }
        } else {
            data = {
                role,
                requirements
            }
        }

        try {

            const response = await fetch(`${process.env.NEXT_PUBLIC_BACKEND_URL}${process.env.NEXT_PUBLIC_BACKEND_API_URL_SUFFIX}/organization/${currentOrganization}/ai/job-description/generate`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    "X-XSRF-Token": getCookie('XSRF-TOKEN') as string,
                    "X-API-Key": process.env.NEXT_PUBLIC_API_KEY_JOB_RCMD_URL as string,
                },
                body: JSON.stringify(data),
            });


            if (!response.body) {
                toast.error('Something went wrong. Please try again later.');
                console.error("Response body is not readable");
                return;
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let firstMessageTimestamp: number | null = null; // To store the first timestamp
            let sessionId: string | null = null;
            setChatLoading(false);

            while (true) {
                const { done, value } = await reader.read();
                if (done) {
                    setCurrentChat((prevChat) => {
                        const updatedMessages = [...prevChat.messages].map((message) => {
                            if (message.sender === 'bot') {
                                return { ...message, showInsertButton: true };
                            }
                            return message;
                        });
                        return {
                            ...prevChat,
                            messages: updatedMessages,
                        };
                    });

                    break;
                }

                const chunk = decoder.decode(value, { stream: true });
                const textSegments = [...chunk.matchAll(/"text": "(.*?)"/g)].map(match => match[1]);
                const sessionIdMatch = chunk.match(/"session_id": "(.*?)"/);
                sessionId = sessionIdMatch ? sessionIdMatch[1] : sessionId;

                const parsedText = textSegments.join('');
                let message = parsedText;

                if (!firstMessageTimestamp) {
                    firstMessageTimestamp = Date.now(); // Only set the first timestamp once
                    const searchValue = 'Assistant> ';
                    message = parsedText.startsWith(searchValue) ? parsedText.replace(searchValue, ASSISTANT_NAME + '> ') : parsedText;
                }

                // Create or update the current chat
                setCurrentChat((prevChat) => {
                    if (!prevChat) {
                        // If no chat exists, initialize a new one
                        return {
                            id: sessionId || 'unknown',
                            title: `Chat for ${role}`,
                            messages: [
                                {
                                    id: 1,
                                    timestamp: firstMessageTimestamp,
                                    sender: 'bot',
                                    message,
                                },
                            ],
                        };
                    }

                    // Update the existing chat
                    const updatedMessages = [...prevChat.messages];
                    const existingMessage = updatedMessages.find(
                        (msg: Message) => msg.timestamp === firstMessageTimestamp
                    );

                    if (existingMessage) {
                        // Append the chunk to the existing message
                        existingMessage.message += message;
                    }

                    return { ...prevChat, messages: updatedMessages };
                });
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again later.');
            console.error("Failed to receive stream:", error);
        }

    }

    const [currentImproveChat, setCurrentImproveChat] = useState<{
        chat: Chat | null;
        loading: boolean;
    }>({
        chat: null,
        loading: false,
    });

    const addNewImproveChat = async ({ existing_description, modifications = '' }: { existing_description: string, modifications?: string }) => {

        setCurrentImproveChat((prevChat) => ({
            ...prevChat,
            loading: true,
        }));
        const data = {
            existing_description: stripHtmlTags(existing_description),
            modifications,
        }


        try {

            const response = await fetch(`${process.env.NEXT_PUBLIC_BACKEND_URL}${process.env.NEXT_PUBLIC_BACKEND_API_URL_SUFFIX}/organization/${currentOrganization}/ai/job-description/improve`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    "X-XSRF-Token": getCookie('XSRF-TOKEN') as string,
                    "X-API-Key": process.env.NEXT_PUBLIC_API_KEY_JOB_RCMD_URL as string,
                },
                body: JSON.stringify(data),
            });

            if (!response.body) {
                toast.error('Something went wrong. Please try again later.');
                console.error("Response body is not readable");
                return;
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let firstMessageTimestamp: number | null = null; // To store the first timestamp
            let sessionId: string | null = null;
            setCurrentImproveChat({
                chat: {
                    id: sessionId || 'unknown',
                    title: `Chat for ${'role'}`,
                    messages: [
                        {
                            id: 1,
                            timestamp: Date.now(),
                            sender: 'user',
                            message: turndownService.turndown(existing_description),
                        },
                    ],
                },
                loading: true,
            });

            while (true) {
                const { done, value } = await reader.read();
                if (done) {
                    setCurrentImproveChat((prevState) => {
                        if (!prevState.chat) return prevState;
                        const updatedMessages = [...prevState.chat.messages].map((message) => {
                            if (message.sender === 'bot') {
                                return { ...message, showInsertButton: true };
                            }
                            return message;
                        });

                        return {
                            ...prevState,
                            chat: {
                                ...prevState.chat,
                                messages: updatedMessages
                            },
                            loading: false,
                        }
                    });

                    break;
                }

                const chunk = decoder.decode(value, { stream: true });
                const textSegments = [...chunk.matchAll(/"text": "(.*?)"/g)].map(match => match[1]);
                const sessionIdMatch = chunk.match(/"session_id": "(.*?)"/);
                sessionId = sessionIdMatch ? sessionIdMatch[1] : sessionId;

                const parsedText = textSegments.join('');
                let message = parsedText;

                if (!firstMessageTimestamp) {
                    firstMessageTimestamp = Date.now(); // Only set the first timestamp once
                    const searchValue = 'Assistant> ';
                    message = parsedText.startsWith(searchValue) ? parsedText.replace(searchValue, ASSISTANT_NAME + '> ') : parsedText;
                }

                // Create or update the current chat

                setCurrentImproveChat((prevState) => {
                    if (!prevState.chat) return prevState;

                    const updatedMessages = [...prevState.chat.messages];
                    const existingMessage = updatedMessages.find(
                        (msg: Message) => msg.timestamp === firstMessageTimestamp && msg.sender === 'bot'
                    );

                    if (existingMessage) {
                        // Append the chunk to the existing message
                        existingMessage.message += message;
                    } else {
                        // Create a new bot message
                        updatedMessages.push({
                            id: prevState.chat.messages.length + 1,
                            timestamp: firstMessageTimestamp,
                            sender: 'bot', // Bot's response
                            message: message,
                        });
                    }

                    return {
                        ...prevState,
                        chat: {
                            ...prevState.chat,
                            id: sessionId || 'unknown',
                            messages: updatedMessages,
                        },
                    };
                });
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again later.');
            console.error("Failed to receive stream:", error);
        }

    }

    const updateChat = async ({ userMessage, type }: { userMessage: string; type: ChatType }) => {
        if (!userMessage) return;

        const isImproveChat = type === 'IMPROVE';
        const currentChatContext = isImproveChat ? currentImproveChat.chat : currentChat;


        if (!currentChatContext) {
            console.error("No current chat available to update.");
            return;
        }

        const id = currentChatContext.id;

        // Add the user's message to the current chat
        const userMessageId = currentChatContext.messages.length + 1; // Unique ID for the user's message
        const newMessage = {
            id: userMessageId,
            timestamp: Date.now(),
            sender: 'user',
            message: userMessage,
        };

        if (isImproveChat) {
            setCurrentImproveChat((prevState) => {
                if (!prevState?.chat) return prevState;
                return {
                    ...prevState,
                    chat: {
                        ...prevState.chat,
                        messages: [...prevState.chat.messages, newMessage],
                    },
                    loading: true,
                };
            });
        } else {
            setCurrentChat((prevChat) => {
                if (!prevChat) return null;
                return { ...prevChat, messages: [...prevChat.messages, newMessage] };
            });
            setChatLoading(true);
        }


        try {
            const response = await fetch(`${process.env.NEXT_PUBLIC_BACKEND_URL}${process.env.NEXT_PUBLIC_BACKEND_API_URL_SUFFIX}/organization/${currentOrganization}/ai/job-description/chat`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    "X-XSRF-Token": getCookie('XSRF-TOKEN') as string,
                    "X-API-Key": process.env.NEXT_PUBLIC_API_KEY_JOB_RCMD_URL as string,
                },
                body: JSON.stringify({ session_id: id, message: userMessage }),
            });

            if (!response.body) {
                toast.error('Something went wrong. Please try again later.');
                console.error("Response body is not readable");
                if (isImproveChat) {
                    setCurrentImproveChat((prevState) => ({ ...prevState, loading: false }));
                } else {
                    setChatLoading(false);
                }
                return;
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let firstMessageTimestamp: number | null = null;

            // Process streaming response
            while (true) {
                const { done, value } = await reader.read();
                if (done) {
                    if (isImproveChat) {
                        setCurrentImproveChat((prevState) => {
                            if (!prevState?.chat) return prevState;
                            const updatedMessages = prevState.chat.messages.map((message) => {
                                if (message.sender === 'bot') {
                                    return { ...message, showInsertButton: true };
                                }
                                return message;
                            });
                            return {
                                ...prevState,
                                chat: {
                                    ...prevState.chat,
                                    messages: updatedMessages,
                                },
                            };
                        });
                    } else {
                        setCurrentChat((prevChat) => {
                            if (!prevChat) return null;
                            const updatedMessages = prevChat.messages.map((message) => {
                                if (message.sender === 'bot') {
                                    return { ...message, showInsertButton: true };
                                }
                                return message;
                            });
                            return { ...prevChat, messages: updatedMessages };
                        });
                    }
                    break;
                }

                const chunk = decoder.decode(value, { stream: true });
                const textSegments = [...chunk.matchAll(/"text": "(.*?)"/g)].map((match) => match[1]);
                let parsedText = textSegments.join('');

                if (!firstMessageTimestamp) {
                    firstMessageTimestamp = Date.now(); // Set the first timestamp
                    const searchValue = 'Assistant> ';
                    parsedText = parsedText.startsWith(searchValue)
                        ? parsedText.replace(searchValue, ASSISTANT_NAME + '> ')
                        : parsedText;
                }

                // Update the current chat with the bot's response
                if (isImproveChat) {
                    setCurrentImproveChat((prevState) => {
                        if (!prevState?.chat) return prevState;

                        const updatedMessages = [...prevState.chat.messages];
                        const existingMessage = updatedMessages.find(
                            (msg) => msg.timestamp === firstMessageTimestamp && msg.sender === 'bot'
                        );

                        if (existingMessage) {
                            existingMessage.message += parsedText;
                        } else {
                            const newBotMessage = {
                                id: userMessageId + 1,
                                timestamp: firstMessageTimestamp,
                                sender: 'bot',
                                message: parsedText,
                                showInsertButton: false,
                            };
                            updatedMessages.push(newBotMessage);
                        }

                        return {
                            ...prevState,
                            chat: { ...prevState.chat, messages: updatedMessages },
                        };
                    });
                } else {
                    setCurrentChat((prevChat) => {
                        if (!prevChat) return null;

                        const updatedMessages = [...prevChat.messages];
                        const existingMessage = updatedMessages.find(
                            (msg) => msg.timestamp === firstMessageTimestamp && msg.sender === 'bot'
                        );

                        if (existingMessage) {
                            existingMessage.message += parsedText;
                        } else {
                            const newBotMessage = {
                                id: userMessageId + 1,
                                timestamp: firstMessageTimestamp,
                                sender: 'bot',
                                message: parsedText,
                                showInsertButton: false,
                            };
                            updatedMessages.push(newBotMessage);
                        }

                        return { ...prevChat, messages: updatedMessages };
                    });
                }
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again later.');
            console.error("Failed to receive stream:", error);
        } finally {
            if (isImproveChat) {
                setCurrentImproveChat((prevState) => ({ ...prevState, loading: false }));
            } else {
                setChatLoading(false);
            }
        }
    };


    const improveMessage = async ({ userMessage, modifications = '' }: { userMessage: string, modifications?: string }) => {

        // Create a new state for the improved message
        setImprovedMessageState({
            loading: true,
            message: "",
            showInsertButton: false,
        });

        try {
            const response = await fetch(`${process.env.NEXT_PUBLIC_BACKEND_URL}${process.env.NEXT_PUBLIC_BACKEND_API_URL_SUFFIX}/organization/${currentOrganization}/ai/job-description/improve`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    "X-XSRF-Token": getCookie('XSRF-TOKEN') as string,
                    "X-API-Key": process.env.NEXT_PUBLIC_API_KEY_JOB_RCMD_URL as string,
                },
                body: JSON.stringify({ existing_description: JSON.stringify(userMessage), modifications: modifications }),
            });

            if (!response.body) {
                toast.error('Something went wrong. Please try again later.');
                console.error("Response body is not readable");
                setImprovedMessageState({ loading: false, message: "", showInsertButton: false });
                return;
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            // let improvedMessage = "";
            let firstMessageTimestamp: number | null = null;
            setImprovedMessageState(prevState => ({ ...prevState, loading: false }));

            while (true) {
                const { done, value } = await reader.read();
                if (done) {
                    setImprovedMessageState(prevState => ({ ...prevState, showInsertButton: true }));
                    break;
                }

                const chunk = decoder.decode(value, { stream: true });
                const textSegments = [...chunk.matchAll(/"text":\s*"(.*?)"/g)].map((match) => match[1]);
                let improvedMessage = textSegments.join("");


                if (!firstMessageTimestamp) {
                    firstMessageTimestamp = Date.now(); // Set the first timestamp
                    const searchValue = 'Assistant> ';
                    improvedMessage = improvedMessage.startsWith(searchValue) ? improvedMessage.replace(searchValue, ASSISTANT_NAME + '> ') : improvedMessage;
                }

                // Update the improved message state incrementally
                setImprovedMessageState((prevState) => ({
                    ...prevState,
                    message: (prevState.message || "") + improvedMessage,
                }));
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again later.');
            console.error("Failed to improve message:", error);
            setImprovedMessageState({ loading: false, message: "", showInsertButton: false });
        }
    };


    return (
        <AIContext.Provider value={{
            AIServerStatus, addNewChat, chatLoading, checkHealth, currentChat, setAIServerStatus, setChatLoading, setCurrentChat, updateChat, improveMessage, improvedMessageState,
            addNewImproveChat,
            currentImproveChat,
            setCurrentImproveChat
        }}>
            {children}
        </AIContext.Provider>
    )
}

export const useJobsAI = () => useContext(AIContext);