export type OrganizationData = {
    name: string;
    id: string;
    logo: {
        small: {
            url: string;
            alt: string;
        };
        medium: {
            url: string;
            alt: string;
        };
        large: {
            url: string;
            alt: string;
        };
    };
};

export const organizations: Record<string, OrganizationData> = {
    "01G682MQPFM8K0SAPMSZP3WQ3Q": {
        name: "Abroadworks",
        id: "01G682MQPFM8K0SAPMSZP3WQ3Q",
        logo: {
            small: {
                url: "/images/logo-small.svg",
                alt: "Abroadworks",
            },
            medium: {
                url: "/images/logo.svg",
                alt: "Abroadworks",
            },
            large: {
                url: "/images/logo.svg",
                alt: "Abroadworks",
            },
        },
    },
};
