import React from 'react'

type Props = {
    className?: string
}

function UserTie({ className }: Props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" className={className}>
            <path d="M4.28571 5.0088C4.28571 2.24261 6.84375 0 10 0C13.1562 0 15.7143 2.24261 15.7143 5.0088V5.6349C15.7143 8.40149 13.1562 10.6437 10 10.6437C6.84375 10.6437 4.28571 8.40149 4.28571 5.6349V5.0088ZM14.2277 4.3827H12.8839C12.2188 4.3827 11.5848 4.15183 11.0759 3.74095L10.8348 3.4956C9.97321 4.4414 8.62054 5.0088 7.24554 5.0088H5.71429V5.6349C5.71429 7.70886 7.59375 9.39151 10 9.39151C12.3661 9.39151 14.2857 7.70886 14.2857 5.6349V5.0088C14.2857 4.79358 14.2634 4.58619 14.2277 4.3827ZM11.2188 2.06183L12.125 2.85541C12.3259 3.0315 12.5982 3.1305 12.8839 3.1305H13.7143C12.9732 2.00783 11.5848 1.2522 10 1.2522C8.13393 1.2522 6.54464 2.2974 5.95982 3.7566H7.24554C8.44196 3.7566 9.53125 3.16846 10.067 2.23635C10.1027 2.17296 10.1518 2.11387 10.2098 2.06183C10.4866 1.81726 10.942 1.81726 11.2188 2.06183ZM11.4286 11.8959C11.8214 11.8959 12.1429 12.1777 12.1429 12.522C12.1429 12.8664 11.8214 13.1481 11.4286 13.1481H10.9911L11.8795 15.4882L13.5536 12.5572C17.1741 12.8859 20 15.5703 20 18.8339C20 19.4952 19.3839 20 18.6295 20H1.37143C0.613839 20 0 19.4952 0 18.8339C0 15.5703 2.825 12.8859 6.44643 12.5572L8.12054 15.4882L9.00893 13.1481H8.53125C8.17857 13.1481 7.81696 12.8664 7.81696 12.522C7.81696 12.1777 8.17857 11.8959 8.53125 11.8959H11.4286ZM10 18.7478L10.9777 17.0691L10 14.5021L9.02232 17.0691L10 18.7478ZM18.5312 18.7478C18.5446 16.4821 16.7679 14.549 14.3482 13.9581L11.5982 18.7478H18.5312ZM8.40179 18.7478L5.65179 13.9581C3.23348 14.549 1.45446 16.4821 1.42902 18.7478H8.40179Z" />
        </svg>
    )
}

export default UserTie