import { WorkSpaceContext } from "@/context/workSpaceContext"
import { getFilterQueryKey, getQueryKey, useRouteKey } from "@aw/table"
import fetcher from 'lib/fetcher'
import React, { useContext } from "react"
// import { URI_QUERY_PREFIX } from "../main"
import useQueryParams from "@/hooks/useQueryParams"
import CustomDropdown from "@/components/Common/CustomDropdown"
import { Tooltip } from 'antd'
import axios from "@/lib/axios"
import { Template } from "../types"

export type data = {
    "id": number,
    "name": string
}

type Props = {
    handleChange: ({ type, value }: { type: string, value: string[] | string }) => void
    disabled: boolean,
    value: string[] | string | null
    type: 'header' | 'footer'
}

const TemplateDropdown = ({ handleChange, disabled, value, type }: Props) => {
    return (
        <div className="flex items-start gap-4">
            <div className='w-full'>
                <Dropdown
                    value={value}
                    disabled={disabled}
                    type={type}
                    onChange={(value) => handleChange({ type: type, value })}
                />
            </div>
        </div>
    )
}

export default TemplateDropdown



function Dropdown({ value, onChange, disabled, type }: {
    value: string[],
    onChange: (id: string[] | string) => void,
    disabled: boolean
    type: 'header' | 'footer'
}) {

    type FetchItemsResponse<T> = {
        data: T[];
        links?: {
            next?: string;
        };
    };

    const { currentOrganization } = useContext(WorkSpaceContext)

    const fetchFlows = async (page: number, pageSize: number, search: string = ''): Promise<FetchItemsResponse<Template>> => {
        const apiUrl = `/organization/${currentOrganization}/job/templates/${type}?page=${page}&perPage=${pageSize}`;
        try {
            const response = await axios.get(apiUrl);
            return { data: response.data.rows, links: response.data.links };
        } catch (error) {
            console.error("Error fetching templates:", error);
            return { data: [] };
        }
    };

    if (!currentOrganization) {
        return <div>Loading...</div>;
    }

    return <CustomDropdown<Template>
        value={value}
        onChange={(selectedValue) => onChange(selectedValue)}
        fetchItems={fetchFlows}
        mapResponseToOptions={(items: Template[]) => items.map(({ id, title, content }) => ({
            label: `${title}`,
            value: JSON.stringify({ id, label: title, value: content }),
        }))}
        maxTagPlaceholder={(omittedValues) => (
            <Tooltip
                overlayStyle={{ pointerEvents: 'none' }}
                title={omittedValues.map(({ label }) => label).join(', ')}
            >
                <span>+{omittedValues?.length}</span>
            </Tooltip>
        )}
        placeholder="Select Template"
        disabled={disabled}
    />
}