import { WorkSpaceContext } from "@/context/workSpaceContext";
import { getCookie } from "@/lib/axios";
import { ASSISTANT_NAME } from "@/modules/organization/jobs/listings/create-job-form";
import { fetcher } from "@aw/lib";
import { createContext, useContext, useState } from 'react';
import toast from "react-hot-toast";

type Message = {
    id: number;
    timestamp: number;
    sender: 'bot' | 'user';
    message: string;
    showInsertButton?: boolean;
}

type Chat = {
    id: string;
    title: string;
    messages: Message[];
}

type AIServerStatus = {
    loading: boolean;
    success: boolean;
}

type ImprovedMessageStateType = {
    loading: boolean;
    message: null | string;
    showInsertButton?: boolean;
}

type SubjectStateType = {
    subject: string;
    loading: boolean;
    error: string | null;
}

type EMAIL_INPUT = {
    "recipient_type": string,
    "recipient_data": {},
    "user_message": string,
    "additional_context": {
        "user": string
    }
}

type AIContextType = {
    deleteChat: () => void;
    currentChat: Chat | null;
    chatLoading: boolean;
    AIServerStatus: AIServerStatus;
    setAIServerStatus: any;
    setCurrentChat: any;
    setChatLoading: any;
    checkHealth: () => Promise<void>;
    addNewChat: (data: EMAIL_INPUT) => Promise<void>;
    updateChat: (params: { userMessage: string }) => Promise<void>;
    generateSubject: () => Promise<void>;
    subjectState: SubjectStateType;
}

const defaultAIContext: AIContextType = {
    deleteChat: () => { },
    currentChat: null,
    chatLoading: false,
    AIServerStatus: { loading: false, success: false },
    setCurrentChat: () => { },
    setChatLoading: () => { },
    setAIServerStatus: () => { },
    checkHealth: async () => { },
    addNewChat: async () => { },
    updateChat: async () => { },
    generateSubject: async () => { },
    subjectState: { subject: '', loading: false, error: null },
};

const AIContext = createContext(defaultAIContext);

const API_PREFIX = `http://13.202.225.33:8000`

const GENERATE_EMAIL_BODY = `ai/email/generate`
const CHAT_EMAIL_BODY = `ai/email/chat`
const GENERATE_SUBJECT = `ai/email/subject`
const HEALTH = `ai/health`


function getAPIPrefix({
    currentOrganization,
    uri
}: {
    currentOrganization: string,
    uri: string
}) {
    if (!currentOrganization) return ''
    return `${process.env.NEXT_PUBLIC_BACKEND_URL}${process.env.NEXT_PUBLIC_BACKEND_API_URL_SUFFIX}/organization/${currentOrganization}/${uri}`
}


export const EmailAIProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [currentChat, setCurrentChat] = useState<Chat | null>(null);
    const [chatLoading, setChatLoading] = useState(false);
    const [subjectState, setSubjectState] = useState<SubjectStateType>({ subject: '', loading: false, error: null });




    const [AIServerStatus, setAIServerStatus] = useState({
        loading: false,
        success: false,
    });

    const { currentOrganization } = useContext(WorkSpaceContext)

    const checkHealth = async () => {
        if (!AIServerStatus.loading) {
            setAIServerStatus({
                loading: true,
                success: false,
            });
        }
        await fetch(getAPIPrefix({ currentOrganization: currentOrganization, uri: HEALTH }), {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                "X-XSRF-Token": getCookie('XSRF-TOKEN') as string,
                "X-API-Key": 'your-secret-key-here' as string,
            }
        }).then(() => {
            setAIServerStatus({
                loading: false,
                success: true,
            });
        }).catch(() => {
            setAIServerStatus({
                loading: false,
                success: false,
            });
        })
    }

    const addNewChat = async (data: EMAIL_INPUT) => {

        setChatLoading(true)


        try {

            const response = await fetch(getAPIPrefix({ currentOrganization: currentOrganization, uri: GENERATE_EMAIL_BODY }), {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    "X-XSRF-Token": getCookie('XSRF-TOKEN') as string,
                    "X-API-Key": 'your-secret-key-here' as string,
                },
                body: JSON.stringify(data),
            });


            if (!response.body) {
                toast.error('Something went wrong. Please try again later.');
                console.error("Response body is not readable");
                return;
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let firstMessageTimestamp: number | null = null; // To store the first timestamp
            let sessionId: string | null = null;
            setChatLoading(false);

            while (true) {
                const { done, value } = await reader.read();
                if (done) {
                    setCurrentChat((prevChat) => {
                        const updatedMessages = [...prevChat.messages].map((message) => {
                            if (message.sender === 'bot') {
                                return { ...message, showInsertButton: true };
                            }
                            return message;
                        });
                        return {
                            ...prevChat,
                            messages: updatedMessages,
                        };
                    });

                    break;
                }

                const chunk = decoder.decode(value, { stream: true });
                const textSegments = [...chunk.matchAll(/"text": "(.*?)"/g)].map(match => match[1]);
                const sessionIdMatch = chunk.match(/"session_id": "(.*?)"/);
                sessionId = sessionIdMatch ? sessionIdMatch[1] : sessionId;

                const parsedText = textSegments.join('');
                let message = parsedText;

                if (!firstMessageTimestamp) {
                    firstMessageTimestamp = Date.now(); // Only set the first timestamp once
                    const searchValue = 'Assistant> ';
                    message = parsedText.startsWith(searchValue) ? parsedText.replace(searchValue, ASSISTANT_NAME + '> ') : parsedText;
                }

                // Create or update the current chat
                setCurrentChat((prevChat) => {
                    if (!prevChat) {
                        // If no chat exists, initialize a new one
                        return {
                            id: sessionId || 'unknown',
                            title: `Send Email`,
                            messages: [
                                {
                                    id: 1,
                                    timestamp: firstMessageTimestamp,
                                    sender: 'bot',
                                    message,
                                },
                            ],
                        };
                    }

                    // Update the existing chat
                    const updatedMessages = [...prevChat.messages];
                    const existingMessage = updatedMessages.find(
                        (msg: Message) => msg.timestamp === firstMessageTimestamp
                    );

                    if (existingMessage) {
                        // Append the chunk to the existing message
                        existingMessage.message += message;
                    }

                    return { ...prevChat, messages: updatedMessages };
                });
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again later.');
            console.error("Failed to receive stream:", error);
        }

    }

    const updateChat = async ({ userMessage }: { userMessage: string }) => {
        if (!currentChat) {
            console.error("No current chat available to update.");
            return;
        }

        const id = currentChat.id;

        // Add the user's message to the current chat
        const userMessageId = currentChat.messages.length + 1; // Unique ID for the user's message
        const newMessage = {
            id: userMessageId,
            timestamp: Date.now(),
            sender: 'user',
            message: userMessage,
        };

        setCurrentChat((prevChat) => {
            if (!prevChat) return null;
            return { ...prevChat, messages: [...prevChat.messages, newMessage] };
        });

        setChatLoading(true);

        try {
            const response = await fetch(getAPIPrefix({ currentOrganization: currentOrganization, uri: CHAT_EMAIL_BODY }), {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    "X-XSRF-Token": getCookie('XSRF-TOKEN') as string,
                    "X-API-Key": 'your-secret-key-here' as string,
                },
                body: JSON.stringify({ session_id: id, message: userMessage }),
            });

            if (!response.body) {
                toast.error('Something went wrong. Please try again later.');
                console.error("Response body is not readable");
                setChatLoading(false);
                return;
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let firstMessageTimestamp: number | null = null;

            // Process streaming response
            while (true) {
                const { done, value } = await reader.read();
                if (done) {
                    setCurrentChat((prevChat) => {
                        const updatedMessages = [...prevChat.messages].map((message) => {
                            if (message.sender === 'bot') {
                                return { ...message, showInsertButton: true };
                            }
                            return message;
                        });
                        return {
                            ...prevChat,
                            messages: updatedMessages,
                        };
                    });

                    break;
                }

                const chunk = decoder.decode(value, { stream: true });
                const textSegments = [...chunk.matchAll(/"text": "(.*?)"/g)].map((match) => match[1]);
                let parsedText = textSegments.join('');

                if (!firstMessageTimestamp) {
                    firstMessageTimestamp = Date.now(); // Set the first timestamp
                    const searchValue = 'Assistant> ';
                    parsedText = parsedText.startsWith(searchValue) ? parsedText.replace(searchValue, ASSISTANT_NAME + '> ') : parsedText;
                }



                // Update the current chat with the bot's response
                setCurrentChat((prevChat) => {
                    if (!prevChat) return null;

                    // Append or update the bot's message
                    const updatedMessages = [...prevChat.messages];
                    const existingMessage = updatedMessages.find(
                        (msg) => msg.timestamp === firstMessageTimestamp && msg.sender === 'bot'
                    );

                    if (existingMessage) {
                        existingMessage.message += parsedText;
                    } else {
                        const newBotMessage = {
                            id: userMessageId + 1,
                            timestamp: firstMessageTimestamp,
                            sender: 'bot',
                            message: parsedText,
                            showInsertButton: false,
                        };
                        updatedMessages.push(newBotMessage);
                    }

                    return { ...prevChat, messages: updatedMessages };
                });
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again later.');
            console.error("Failed to receive stream:", error);
        } finally {
            setChatLoading(false);
        }
    };


    const deleteChat = async () => {
        setCurrentChat(null);
    }

    const generateSubject = async () => {
        if (!currentChat) {
            console.error("No current chat available to generate the subject.");
            return;
        }

        const id = currentChat.id;

        setSubjectState((prevState) => ({
            subject: '',
            loading: true,
            error: null,
        }));

        try {
            const response = await fetch(getAPIPrefix({ currentOrganization: currentOrganization, uri: GENERATE_SUBJECT }), {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    "X-XSRF-Token": getCookie('XSRF-TOKEN') as string,
                    "X-API-Key": 'your-secret-key-here' as string,
                },
                body: JSON.stringify({ session_id: id }),
            });

            if (!response.body) {
                toast.error('Failed to generate subject. Please try again later.');
                console.error("Response body is not readable");
                setSubjectState({
                    subject: '',
                    loading: false,
                    error: 'Response body is not readable',
                });
                return;
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let firstMessageTimestamp: number | null = null;

            // Process streaming response
            while (true) {
                const { done, value } = await reader.read();
                if (done) break;

                const chunk = decoder.decode(value, { stream: true });
                const textSegments = [...chunk.matchAll(/"text": "(.*?)"/g)].map((match) => match[1]);
                let parsedText = textSegments.join('');

                if (!firstMessageTimestamp) {
                    firstMessageTimestamp = Date.now(); // Set the first timestamp
                    const searchValue = 'Assistant> ';
                    parsedText = parsedText.startsWith(searchValue) ? parsedText.replace(searchValue, '') : parsedText;
                }

                setSubjectState((prevState) => ({
                    ...prevState,
                    subject: prevState.subject + parsedText,
                    loading: false,
                    error: null,
                }))
            }

        } catch (error: any) {
            console.error("Failed to generate subject:", error);
            setSubjectState({
                subject: '',
                loading: false,
                error: error.message || 'Something went wrong!',
            });
        }
    };




    return (
        <AIContext.Provider value={{
            deleteChat,
            generateSubject,
            subjectState,
            AIServerStatus, addNewChat, chatLoading, checkHealth, currentChat, setAIServerStatus, setChatLoading, setCurrentChat, updateChat
        }}>
            {children}
        </AIContext.Provider>
    )
}

export const useEmailGeneratorAI = () => useContext(AIContext);