import React from 'react'

type Props = {
    className?: string
}
function Pencil({ className }: Props) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`fill-neutralgray-10 ${className}`}>
            <path
                id="pen-light"
                d="M12.3776 1.2293C12.6714 0.935547 13.1464 0.935547 13.437 1.2293L14.7682 2.56055C15.062 2.8543 15.062 3.3293 14.7682 3.61992L13.0589 5.33555L10.6651 2.9418L12.3776 1.2293ZM9.95886 3.64805L12.3526 6.0418L4.99637 13.3949C4.75886 13.6324 4.46824 13.8043 4.14636 13.898L1.24636 14.7543L2.09949 11.8543C2.19324 11.5324 2.36824 11.2418 2.60261 11.0043L9.95886 3.64805ZM14.1464 0.523047C13.462 -0.161328 12.3557 -0.161328 11.6714 0.523047L1.89636 10.2949C1.54011 10.6512 1.28074 11.0887 1.14011 11.5699L0.0307398 15.3512C-0.0223852 15.5262 0.0276148 15.7168 0.15574 15.8449C0.283865 15.973 0.47449 16.023 0.64949 15.9699L4.43074 14.8574C4.91199 14.7168 5.34949 14.4543 5.70574 14.1012L15.4776 4.3293C16.162 3.64492 16.162 2.53867 15.4776 1.8543L14.1464 0.523047Z"
            />
        </svg>
    )
}

export default Pencil
