import Heading from "@/components/Common/Heading"


import {
    ArrowRight,
    BriefCase,
    Calendar,
    GraduationCap,
    Location,
    MoneyBill,
    SquareDollar,
    Suitcase,
    UserTie,
    Star,
    Pencil,
    Sparkle,
    Bookmark,
    Building
} from '@aw/icons'


import { FormErrors, Input, TextArea } from "@aw/design-system"
import { DatePicker, Select, DatePickerProps, Popover } from "antd"
import React, { useState, useEffect, useMemo, ChangeEvent, useContext, useRef, useCallback } from 'react'
import 'suneditor/dist/css/suneditor.min.css'
import dynamic from 'next/dynamic'
import { X } from "phosphor-react"
import Button from "@/components/Common/Button"
import { useRouter } from "next/navigation"
import dayjs from "dayjs"
import { WorkSpaceContext } from "@/context/workSpaceContext"
import axios, { getCookie } from "@/lib/axios"
import useSWR, { SWRResponse } from "swr"
import { useForm } from "@aw/hooks"
import toast from "react-hot-toast"
import JobDescription from "./components/job-description"
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import { Modal, useDialog } from "@aw/dialog"

import MarkdownIt from 'markdown-it';
dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)


import JobRecommendationDialog from "./components/job-recommendation-ai"
import { useJobsAI } from "@/context/ai/jobs"
import Link from "next/link"
import AIButtonIcon from "./components/ai-button-icon"
import TemplateDropdown from "./components/template-dropdown"

import { Template } from "../types"
import { Separator } from '@aw/design-system'
import { getCityData, getCountriesData, getExperienceLevelsData, getStateData, getJobTypesData, getJobFieldsData } from "./data"
import CustomSearchSelect from "@/components/Common/CustomSearchSelect"
import { AntdSearchFunction } from "@aw/utils"

type Props = {}

type Message = {
    id: number;
    timestamp: number;
    sender: string;
    message: string;
    showInsertButton?: boolean;
}

export interface Chat {
    id: string;
    title: string;
    messages: Message[];
}

export const JOB_DESC_AI_DIALOG_ID = "JOB_DESC_AI_DIALOG"
export const ASSISTANT_NAME = "Abroadworks AI";
export type ChatType = null | 'GEN' | 'IMPROVE';



function CreateJobForm({ }: Props) {


    const [jobState, setJobState] = useState({
        id: null,
        title: '',
        country_id: null,
        state_id: null,
        city_id: null,
        role: '',
        description: '',
        excerpt: '',
        job_type_id: 1,
        pinned: false,
        source_currency_id: 1,
        target_currency_id: 1,
        salary_from: 0,
        salary_to: 0,
        compensation: 'Hourly',
        required_experiance: '',
        type: 'Primary',
        qualification: '',
        application_deadline: '',
        tags: [],
        status: 'open',
        slug: '',
        interviewer_ids: [],
        question_id: [],
        experience_level_id: 1,
        available_job_field_ids: []
    })

    const [aiDescType, setAiDescType] = useState<ChatType>(null)
    const [openPopOver, setOpenPopOver] = useState(false);
    const popoverRef = useRef<HTMLDivElement | null>(null);


    const [selectedHeader, setSelectedHeader] = useState<Template>({})
    const [selectedFooter, setSelectedFooter] = useState<Template>({})

    const [currencies, setCurrencies] = useState([])

    const { currentOrganization, getOrganizationData } = useContext(WorkSpaceContext)


    // Function to handle the click event outside of the popover
    const handleClickOutside = (event: MouseEvent) => {
        if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
            setOpenPopOver(false);
        }
    };
    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        // Clean up event listener on unmount
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const { title, role, required_experiance, pinned, id, type, qualification, tags, source_currency_id, job_type_id, target_currency_id, status, salary_from, description, excerpt, salary_to, compensation, application_deadline, experience_level_id, city_id, country_id, state_id, available_job_field_ids } = jobState
    function setProperty({ property, value, extraProperties }: {
        property: string;
        value: any;
        extraProperties?: { [key: string]: any }
    }) {
        setJobState({ ...jobState, [property]: value, ...extraProperties })
    }



    const { data: job_types } = getJobTypesData()
    const { data: experience_levels } = getExperienceLevelsData()
    const { data: job_fields } = getJobFieldsData()
    const { data: countries } = getCountriesData()
    const { data: states } = getStateData(country_id)
    const { data: cities } = getCityData(state_id)

    const fetchCurrencies = async (url: string) => {
        return axios.get(url).then(res => res.data)
    }

    const { data: currencyData } = useSWR(`/metadata/index?model=currencies`, fetchCurrencies)


    useEffect(() => {
        if (!currencyData) return
        setCurrencies(currencyData.map(q => ({ label: { name: q.name, symbol: q.symbol }, value: q.id })))
    }, [currencyData])


    const { post, state: formState } = useForm(`/organization/${currentOrganization}/jobs`)
    const saveNewJob = async () => {
        const templates = []
        if (selectedHeader?.id) templates.push(selectedHeader?.id)
        if (selectedFooter?.id) templates.push(selectedFooter?.id)

        post({
            ...jobState,
            description: jobState?.description,
            template_ids: templates,
            application_deadline: jobState?.application_deadline ? dayjs(jobState?.application_deadline).format('YYYY-MM-DD') : ''
        }, {
            onSuccess(res) {
                toast.success(`Job "${title}" created.`)
                const slug = res?.data?.data?.slug
                if (slug) {
                    router.push(`/o/${currentOrganization}/jobs/${slug}`)
                } else {
                    router.push(`/o/${currentOrganization}/jobs`)
                }
            },
            onError(err) {
                console.error(err)
            }
        })

    }
    const [buttonList, setButtonList] = useState()

    const handleDateChange: DatePickerProps['onChange'] = (date, dateString) => {

        setProperty({ property: 'application_deadline', value: dateString })
    }

    function onAddTag(value: string) {

        const _tags: string[] = [...tags, value]

        setProperty({ property: 'tags', value: _tags })
    }
    function onRemoveTag(value: string) {
        const _tags = tags.filter(tag => tag !== value)
        setProperty({ property: 'tags', value: _tags })

    }


    useEffect(() => {
        import('suneditor-react').then(({ buttonList }) => {
            // Once buttonList is dynamically imported, set it to state
            setButtonList(buttonList);
        });
    }, []);

    const [tagFieldInput, setTagFieldInput] = useState('')

    const router = useRouter()


    const { show: showJobDescAiDialog, id: JobDescAiDialogId, hide: hideJobDescAiDialog } = useDialog('JOB_DESC_AI_DIALOG');
    const { AIServerStatus, addNewChat, chatLoading, checkHealth, currentChat, setAIServerStatus, setChatLoading, setCurrentChat, updateChat, improvedMessageState, addNewImproveChat, currentImproveChat, setCurrentImproveChat } = useJobsAI();

    const addDescriptionHandler = (messageId: number) => {

        let _newDesc;
        if (messageId) {
            if (aiDescType === 'GEN') {
                _newDesc = currentChat?.messages.find((msg) => msg.id === messageId)?.message;
            } else {
                _newDesc = currentImproveChat?.chat?.messages.find((msg) => msg.id === messageId)?.message;
            }
            if (!_newDesc) return;

        } else {
            if (!improvedMessageState.message) return
            _newDesc = improvedMessageState.message;
        }

        _newDesc = _newDesc?.startsWith(ASSISTANT_NAME + '> ') ? _newDesc.slice(`${ASSISTANT_NAME}> `.length) : _newDesc;
        const md = new MarkdownIt({
            breaks: true, // Treat \n as <br>
        });

        const htmlContent = md.render(_newDesc.replace(/\\n/g, '\n'));
        setJobState({ ...jobState, description: htmlContent });
        hideJobDescAiDialog();
    };

    const hideJobDescAiDialogHandler = () => {
        hideJobDescAiDialog();
        setAIServerStatus({
            loading: true,
            success: false,
        });
    }

    function onDeleteChat() {
        if (aiDescType === 'IMPROVE') {
            setCurrentImproveChat((prevState) => ({ ...prevState, chat: null, loading: false }));
        } else {
            setCurrentChat(null)
        }
    }



    return (
        <div className="flex flex-col items-center h-full w-full">
            <style>
                {`
                .editor-description{
                color:#2e354a !important;
                font-size:1rem !important;
                
                }
            .editor-description ul {
                font-size:1rem !important;
                 list-style-type: disc;
                 padding-left: 1.5rem;
            }
            .editor-description p span {
                font-size:1rem !important;
                color:#2e354a !important;
            }
            `}
            </style>
            <head>
                <title>Create new Job Listing | {getOrganizationData()?.name}</title>
            </head>
            <div className="flex flex-col gap-6 bg-white w-full desktop:w-[56%] rounded-[1.25rem] border border-neutralgray-4 p-10"
                style={{
                    boxShadow: "0px 1px 2px 0px rgba(18, 20, 22, 0.05)"
                }}
            >

                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-4">
                        <div
                            className="rounded-xl p-4 flex items-center gap-3"
                            style={
                                {
                                    background: "linear-gradient(180deg, #F0F7F7 0%, rgba(240, 247, 247, 0.00) 100%)"
                                }
                            }
                        >
                            <img src={getOrganizationData()?.logo?.small?.url} className="h-8 w-8"
                                alt={getOrganizationData()?.logo?.small?.alt}
                            />
                        </div>
                        <div>

                        </div>
                        <Heading size="h3" className="!text-primary-600">Create new Job Listing</Heading>

                    </div>
                    <div className="flex items-center justify-center h-10 w-10 transition rounded-md
                    hover:bg-[whitesmoke]
                    "
                        role="button"
                        onClick={() => { router.back() }}
                    >
                        <X className="h-7 w-7 cursor-pointer text-neutralgray-7" />
                    </div>
                </div>
                <Separator />
                <div className="flex items-center justify-between">
                    {/* JOB TITLE  */}
                    <div className="flex flex-col gap-4 w-[48%]">
                        <div className="flex gap-3 items-center">
                            <BriefCase className="h-4 w-4 !fill-primary-600" />
                            <FieldLabel>Job Title</FieldLabel>
                        </div>
                        <Input
                            value={title}
                            placeholder="Job title"
                            onChange={(e: any) => setProperty({ property: 'title', value: e.target.value })}
                            className="w-full rounded-[0.625rem] border border-neutralgray-4 px-4 !text-sm text-neutralgray-9 shadow-xs"></Input>
                    </div>

                    {/* YEARS OF EXPERIENCE  */}
                    <div className="flex flex-col gap-4 w-[48%]">
                        <div className="flex gap-3 items-center">
                            <UserTie className="h-4 w-4 !fill-primary-600" />
                            <FieldLabel>Years of Experience</FieldLabel>
                        </div>
                        <Input
                            placeholder="Experience (years)"
                            value={required_experiance}
                            onChange={(e: any) => setProperty({ property: 'required_experiance', value: e.target.value })}
                            className="w-full rounded-[0.625rem] border border-neutralgray-4 px-4 !text-sm text-neutralgray-9 shadow-xs"></Input>
                    </div>
                </div>
                <Separator />
                <div className="flex justify-between">

                    {/*Location  */}
                    <div className="flex flex-col gap-4 w-[48%]">
                        <div className="flex gap-3 items-center">
                            <Location className="h-4 w-4 !fill-primary-600" />
                            <FieldLabel>Preferred Hiring Location</FieldLabel>
                        </div>

                        <Select
                            placeholder="Select a country"
                            size="large"
                            value={country_id}
                            onChange={(e: any) => setProperty({ property: 'country_id', value: e, extraProperties: { state_id: null, city_id: null } })}
                            options={countries?.map((country) => ({ label: country.name, value: country.id }))}
                            onDeselect={() => setProperty({ property: 'country_id', value: null, extraProperties: { state_id: null, city_id: null } })}
                            onClear={() => setProperty({ property: 'country_id', value: null, extraProperties: { state_id: null, city_id: null } })}
                            showSearch
                            filterOption={AntdSearchFunction}
                            allowClear
                        />

                        {country_id && <Select
                            placeholder="Select a State"
                            size="large"
                            value={state_id}
                            onChange={(e: any) => setProperty({ property: 'state_id', value: e, extraProperties: { city_id: null } })}
                            options={states?.map((state) => ({ label: state.name, value: state.id }))}
                            onDeselect={() => setProperty({ property: 'state_id', value: null, extraProperties: { city_id: null } })}
                            onClear={() => setProperty({ property: 'state_id', value: null, extraProperties: { city_id: null } })}
                            showSearch
                            filterOption={AntdSearchFunction}
                            allowClear
                        />}

                        {(state_id && cities?.length) ? <Select
                            placeholder="Select a City"
                            size="large"
                            value={city_id}
                            onChange={(e: any) => setProperty({ property: 'city_id', value: e })}
                            options={cities?.map((city) => ({ label: city.name, value: city.id }))}
                            onDeselect={() => setProperty({ property: 'city_id', value: null })}
                            onClear={() => setProperty({ property: 'city_id', value: null })}
                            showSearch
                            filterOption={AntdSearchFunction}
                            allowClear
                        /> : null}

                    </div>
                    {/* Location */}

                    {/*JOB ROLE  */}
                    <div className="flex flex-col gap-4 w-[48%] !justify-start h-full">
                        <div className="flex gap-3 items-center">
                            <UserTie className="h-4 w-4 !fill-primary-600" />
                            <FieldLabel>Role</FieldLabel>
                        </div>
                        <Input
                            placeholder='Job role'
                            value={role}
                            onChange={(e: any) => setProperty({ property: 'role', value: e.target.value })}
                            className="w-full rounded-[0.625rem] border border-neutralgray-4 px-4 !text-sm text-neutralgray-9 shadow-xs"></Input>
                    </div>


                </div >
                <Separator />
                <div className="flex items justify-between">

                    {/* EXPERIENCE LEVELS */}
                    <div className="flex flex-col gap-4 w-[48%] ">
                        <div className="flex gap-3 items-center">
                            <UserTie className="h-4 w-4 fill-primary-600" />
                            <FieldLabel>Experience Level</FieldLabel>
                        </div>
                        <div className="mt-1 flex items-center gap-2 flex-wrap">
                            {experience_levels?.map((exp, index) => <div className="flex items-center">
                                <label
                                    className={`px-8 h-9 flex items-center justify-center rounded-3xl border border-product-moss-500  transition cursor-pointer text-product-moss-500 ${experience_level_id === exp.id ? 'bg-product-moss-50' : "bg-white hover:bg-product-moss-50"}`}
                                >
                                    {exp.name}
                                    <input
                                        className="hidden"
                                        type='radio'
                                        id={String(exp.name)}
                                        name="experience_levels"
                                        checked={experience_level_id == exp.id}
                                        onChange={(e: any) => setProperty({ property: 'experience_level_id', value: exp.id })}
                                    />
                                </label>
                            </div>)}


                        </div>
                    </div>
                    {/* EXPERIENCE LEVELS */}



                    {/* JOB SETUP  */}
                    <div className="flex flex-col gap-4 w-[48%] ">
                        <div className="flex gap-3 items-center">
                            <Calendar className="h-4 w-4 fill-primary-600" />
                            <FieldLabel>Job Setup</FieldLabel>
                        </div>
                        <div className="mt-1 flex items-center gap-2 flex-wrap">
                            {job_types?.map((job_type, index) => <div className="flex items-center">
                                <label
                                    className={`px-8 h-9 flex items-center justify-center rounded-3xl border border-product-moss-500  transition cursor-pointer text-product-moss-500 ${job_type_id === job_type.id ? 'bg-product-moss-50' : "bg-white hover:bg-product-moss-50"}`}
                                >
                                    {job_type.name}
                                    <input
                                        className="hidden"
                                        type='radio'

                                        id={String(job_type.name)}
                                        name="job_type_id"
                                        checked={job_type_id == job_type.id}
                                        onChange={(e: any) => setProperty({ property: 'job_type_id', value: job_type.id })}
                                    />
                                </label>
                            </div>)}
                        </div>
                    </div>

                </div >
                <Separator />
                <div className="flex items-center justify-between">

                    {/* JOB TYPE */}
                    <div className="flex flex-col gap-4 w-[48%]">
                        <div className="flex gap-3 items-center">
                            <Suitcase className="h-4 w-4 !fill-primary-600" />
                            <FieldLabel>Job Type</FieldLabel>
                        </div>
                        <div className="mt-1 flex items-center gap-2">
                            <div className="flex items-center">

                                <label
                                    className={`px-8 h-9 flex items-center justify-center rounded-3xl border border-product-moss-500  transition cursor-pointer text-product-moss-500 ${type === 'Primary' ? 'bg-product-moss-50' : "hover:bg-product-moss-50 bg-white"}`}
                                >
                                    Primary
                                    <input
                                        className="hidden"
                                        type='radio'
                                        value={type}
                                        id='primary'
                                        name="jobType"
                                        checked={type == 'Primary'}
                                        onChange={(e: any) => setProperty({ property: 'type', value: 'Primary' })}
                                    />
                                </label>
                            </div>
                            <div className="flex items-center">
                                <label
                                    className={`px-8 h-9 flex items-center justify-center rounded-3xl border border-product-moss-500  transition cursor-pointer text-product-moss-500 ${type === 'Portfolio' ? 'bg-product-moss-50' : "hover:bg-product-moss-50 bg-white"}`}
                                >
                                    Portfolio
                                    <input
                                        checked={type == 'Portfolio'}
                                        onChange={(e: any) => setProperty({ property: 'type', value: 'Portfolio' })}
                                        className="hidden"
                                        type='radio'
                                        value={undefined}
                                        id='portfolio'
                                        name="jobType"
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-4 w-[48%]">
                        <div className="flex gap-3 items-center">
                            <Bookmark className="h-4 w-4" />
                            <FieldLabel>Status</FieldLabel>
                        </div>
                        <Select
                            placeholder="Select a status"
                            size="large"
                            value={status ?? undefined}
                            onChange={(e: any) => setProperty({ property: 'status', value: e })}
                            options={[
                                {
                                    label: 'Open',
                                    value: 'open'
                                }, {
                                    label: 'Paused',
                                    value: 'paused'
                                }, {
                                    label: 'Archived',
                                    value: 'archived'
                                }, {
                                    label: 'Hidden',
                                    value: 'hidden'
                                }
                            ]} />
                    </div>

                </div >

                <Separator />
                <div className="flex items-start justify-between">
                    {/* APPLICATION DEADLINE */}
                    <div className="flex flex-col gap-4 w-[48%]">
                        <div className="flex gap-3 items-center">
                            <Calendar className="h-4 w-4 !fill-primary-600" />
                            <FieldLabel>Application Deadline</FieldLabel>
                        </div>

                        <DatePicker
                            format={'MM-DD-YYYY'}
                            size="large"
                            value={application_deadline ? dayjs(application_deadline) : null}
                            onChange={handleDateChange}
                            className="w-64 rounded-[0.625rem] border border-neutralgray-4 px-4 !text-sm text-neutralgray-9 shadow-xs"></DatePicker>
                    </div>



                    <div className=" flex flex-col gap-4 w-[48%]">
                        <div className="flex gap-3 items-center">
                            <Star className="h-4 w-4 !fill-primary-600" />
                            <FieldLabel>Job Tags</FieldLabel>
                        </div>
                        {tags?.length > 0 &&
                            <div className="flex flex-wrap gap-4 items-center ">
                                {tags.map((tag, index) => (
                                    <div key={`skill-${index}`} className='text-neutralgray-8 px-3 py-1  border rounded-3xl border-secondary-500 bg-secondary-50 flex items-center gap-2 capitalize'>{tag}
                                        <div className="h-6 w-6 flex items-center justify-center hover:bg-secondary-50 rounded-full" role="button"
                                            onClick={() => onRemoveTag(tag)}
                                        >
                                            <X className="fill-neutralgray-10 cursor-pointer" />

                                        </div>
                                    </div>
                                ))}
                            </div>
                        }
                        <div className="flex items-center gap-2 justify-stretch">
                            <Input
                                className="flex-1"
                                value={tagFieldInput}
                                placeholder="Add a tag"
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setTagFieldInput(e.target.value)}
                                size="large"
                            />
                            <Button color="primary"
                                className="!w-32"
                                disabled={!tagFieldInput}
                                onClick={() => {
                                    onAddTag(tagFieldInput)
                                    setTagFieldInput('')
                                }}
                            >
                                Add Tag
                            </Button>
                        </div>
                    </div>

                </div>
                <Separator />
                <div className="flex items-center justify-between">
                    {/*SOURCE CURRENCY  */}
                    <div className="flex flex-col gap-4 w-[48%]">
                        <div className="flex gap-3 items-center">
                            <Building className="h-4 w-4 !fill-primary-600" />
                            <FieldLabel>Source Currency</FieldLabel>
                        </div>
                        {currencies && (

                            <Select
                                size="large"
                                showSearch={true}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={
                                    currencies.map(({ label, value }) => {

                                        return { label: `${label?.name} ${label?.symbol}`, value: value }
                                    })
                                }
                                onChange={(e) => { setProperty({ property: 'source_currency_id', value: e }) }}
                                value={source_currency_id}
                            />

                        )}
                    </div>
                    {/* TARGET CURRENCY */}
                    <div className="flex flex-col gap-4 w-[48%]">
                        <div className="flex gap-3 items-center">
                            <Building className="h-4 w-4 !fill-primary-600" />
                            <FieldLabel>Target Currency</FieldLabel>
                        </div>
                        {currencies && (
                            <Select
                                size="large"
                                showSearch={true}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={
                                    currencies.map(({ label, value }) => {

                                        return { label: `${label?.name} ${label?.symbol}`, value: value }
                                    })
                                }
                                onChange={(e) => { setProperty({ property: 'target_currency_id', value: e }) }}
                                value={target_currency_id}
                            />
                        )}
                    </div>

                </div>
                <Separator />
                <div className="flex items-center justify-between">
                    {/*COMPENSATION  */}
                    <div className="flex flex-col gap-4 w-[48%]">
                        <div className="flex gap-3 items-center">
                            <MoneyBill className="h-4 w-4 !fill-primary-600" />
                            <FieldLabel>Compensation</FieldLabel>
                        </div>
                        <Select
                            size="large"
                            placeholder="Select compensation"
                            options={[{
                                label: 'Hourly',
                                value: 'Hourly'
                            }, {
                                label: 'Twice a Month',
                                value: 'Twice Monthly'
                            }, {
                                label: 'Monthly',
                                value: 'Monthly'
                            }]}
                            value={compensation}
                            onChange={(e: any) => { setProperty({ property: 'compensation', value: e }) }}
                        />
                    </div>
                    <div className="flex flex-col gap-4 w-[48%]">
                        <div className="flex gap-3 items-center">
                            <SquareDollar className="h-4 w-4" />
                            <FieldLabel>Pay Range</FieldLabel>
                        </div>
                        <div className="flex items-center gap-2">
                            <Input
                                placeholder="Min"
                                type="number"
                                className="w-32 rounded-[0.625rem] border border-neutralgray-4 px-4 !text-sm text-neutralgray-9 shadow-xs"
                                value={salary_from}
                                onChange={(e: any) => setProperty({ property: 'salary_from', value: e.target.value })}

                            ></Input>

                            <ArrowRight className="h-6 w-6 !fill-neutralgray-7" />

                            <Input
                                placeholder="Max"
                                type="number"
                                value={salary_to}
                                onChange={(e: any) => setProperty({ property: 'salary_to', value: e.target.value })}
                                className="w-32 rounded-[0.625rem] border border-neutralgray-4 px-4 !text-sm text-neutralgray-9 shadow-xs"></Input>
                        </div>
                    </div>
                </div>
                <Separator />
                <div className="flex items-center justify-between">

                    {/*QUALIFICATION  */}
                    <div className="flex flex-col gap-4 w-[48%]">
                        <div className="flex gap-3 items-center">
                            <GraduationCap className="h-4 w-4" />
                            <FieldLabel>Qualification</FieldLabel>
                        </div>
                        <Input
                            value={qualification}
                            placeholder="Qualification"
                            onChange={(e: any) => setProperty({ property: 'qualification', value: e.target.value })}
                            className="w-full rounded-[0.625rem] border border-neutralgray-4 px-4 !text-sm text-neutralgray-9 shadow-xs"></Input>
                    </div>


                    {/*INDUSTRY  */}
                    <div className="flex flex-col gap-4 w-[48%]">
                        <div className="flex gap-3 items-center">
                            <Building className="h-4 w-4" />
                            <FieldLabel>Industry</FieldLabel>
                        </div>
                        <Select mode="multiple" size="large" placeholder="Industry" options={job_fields?.map(j => ({ label: j.name, value: j.id }))} value={available_job_field_ids} onChange={(e: any) => setProperty({ property: 'available_job_field_ids', value: e })} />
                    </div>


                </div>
                <Separator />
                <div className="flex flex-col gap-4 w-full">
                    <div className="flex gap-3 items-center justify-between">
                        <FieldLabel>Job Description</FieldLabel>
                        <div>

                            {description.length > 20 ? <Popover
                                content={<div className="flex flex-col gap-2">
                                    <div className="cursor-pointer hover:text-blue-500" onClick={() => {
                                        showJobDescAiDialog();
                                        checkHealth();
                                        setAiDescType('GEN');
                                        setOpenPopOver(false);
                                    }}>Generate</div>
                                    <div className="cursor-pointer hover:text-blue-500" onClick={() => {
                                        showJobDescAiDialog();
                                        checkHealth();
                                        setAiDescType('IMPROVE');
                                        setOpenPopOver(false);
                                    }}>Improve</div>
                                </div>}
                                title="AI Assistance Options"
                                trigger='click'
                                open={openPopOver}


                            >
                                <div ref={popoverRef} onClick={() => { setOpenPopOver(true); }}><Button color='secondary'>
                                    <div className="flex h-full gap-2 w-full items-center justify-center">
                                        <Sparkle className="h-4 w-4 !fill-primary-500" />
                                        Write with AI
                                    </div>
                                </Button> </div>
                            </Popover>
                                :
                                <div onClick={() => { showJobDescAiDialog(); checkHealth(); setAiDescType('GEN') }}>
                                    <Button color='secondary'>
                                        <div className="flex h-full w-full gap-2 items-center justify-center">
                                            <Sparkle className="h-4 w-4 !fill-primary-500" />
                                            Write with AI
                                        </div>
                                    </Button>
                                </div>}
                        </div>

                    </div>
                    <div className="">
                        <div className="flex flex-col gap-2 w-full mb-2">
                            <div className="flex gap-2 items-center">
                                <FieldLabel className="!text-sm">Header</FieldLabel>
                            </div>
                            <TemplateDropdown
                                type={'header'}
                                value={selectedHeader?.label ? JSON.stringify(selectedHeader) : null}
                                disabled={false}
                                handleChange={(e) => {
                                    setSelectedHeader(JSON.parse(e.value as string))
                                }}
                            />
                        </div>

                        {
                            selectedHeader?.value
                                ? <div className="rounded-[0.75rem] editor-description  bg-neutralgray-2 p-4 mt-2">
                                    <div className="flex w-full justify-end mb-2">
                                        <div className="flex items-center gap-2">
                                            <Link
                                                href={`/o/${currentOrganization}/settings/jobs/templates/header?table%5BJOB-TEMPLATE%5D%5Bpage%5D=0&table%5BJOB-TEMPLATE%5D%5Bfilter%5D=template_id%2Caction&table%5BJOB-TEMPLATE%5D%5Bfilter%5D%5Btemplate_id%5D=${selectedHeader?.id}&table%5BJOB-TEMPLATE%5D%5Bfilter%5D%5Baction%5D=edit`}
                                                target="_blank"
                                                title="Edit Template"
                                            >
                                                <Pencil className="!fill-primary-500 h-4 w-4" />
                                            </Link>
                                            <div
                                                onClick={() => {
                                                    setSelectedHeader({})
                                                }}
                                                title='Remove'
                                            >
                                                <X className="text-error-500 h-4 w-4 cursor-pointer" />
                                            </div>
                                        </div>
                                    </div>
                                    <div dangerouslySetInnerHTML={{ __html: selectedHeader.value || "" }} className="rounded-[0.75rem] editor-description  bg-neutralgray-2 p-2 pt-0" />
                                </div>
                                : ''
                        }
                    </div>
                    <JobDescription
                        description={description}
                        setProperty={setProperty}
                    />
                    <div>
                        <div className="flex flex-col gap-2 w-full mb-2">
                            <div className="flex gap-2 items-center">
                                <FieldLabel className="!text-sm">Footer</FieldLabel>
                            </div>
                            <TemplateDropdown
                                type={'footer'}
                                value={selectedFooter?.label ? JSON.stringify(selectedFooter) : null}
                                disabled={false}
                                handleChange={(e) => {
                                    setSelectedFooter(JSON.parse(e.value as string))
                                }}
                            />
                        </div>

                        {
                            selectedFooter?.value
                                ? <div className="rounded-[0.75rem] bg-neutralgray-2 p-4 mt-2">
                                    <div className="flex w-full justify-end mb-2">
                                        <div className="flex items-center gap-2">
                                            <Link
                                                href={`/o/${currentOrganization}/settings/jobs/templates/footer?table%5BJOB-TEMPLATE%5D%5Bpage%5D=0&table%5BJOB-TEMPLATE%5D%5Bfilter%5D=template_id%2Caction&table%5BJOB-TEMPLATE%5D%5Bfilter%5D%5Btemplate_id%5D=${selectedFooter?.id}&table%5BJOB-TEMPLATE%5D%5Bfilter%5D%5Baction%5D=edit`}
                                                target="_blank"
                                                title="Edit Template"
                                            >
                                                <Pencil className="!fill-primary-500 h-4 w-4" />
                                            </Link>
                                            <div
                                                onClick={() => {
                                                    setSelectedFooter({})
                                                }}
                                                title='Remove'
                                            >
                                                <X className="text-error-500 h-4 w-4 cursor-pointer" />
                                            </div>
                                        </div>
                                    </div>
                                    <div dangerouslySetInnerHTML={{ __html: selectedFooter.value || "" }} className="rounded-[0.75rem] editor-description  bg-neutralgray-2 p-2 pt-0" />
                                </div>
                                : ''
                        }
                    </div>

                </div>
                <Separator />
                <div className="flex flex-col gap-2 w-full">
                    <div className="flex gap-2 items-center">
                        <FieldLabel>Short Description</FieldLabel>
                    </div>
                    <TextArea className="w-32 rounded-[0.625rem]  px-4 !text-sm text-neutralgray-9 shadow-xs"
                        id="description"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setProperty({ property: 'excerpt', value: e.target.value })}
                        value={excerpt}
                    />
                </div>

                <FormErrors state={formState} />
                <div className="flex items-center justify-end">
                    <Button color="primary"
                        className='!bg-primary-600 hover:!bg-primary-700'
                        onClick={saveNewJob}
                        disabled={formState.busy}
                        loading={formState.busy}
                        loadingText={'Saving'}
                    >
                        Save
                    </Button>
                </div>
            </div >
            <Modal id={JOB_DESC_AI_DIALOG_ID} transition="slide" size="6xl" drawer={false} hideOnClickOutside={false} hideOnEsc={true} >
                {initialRef => <JobRecommendationDialog
                    initialRef={initialRef}
                    hideJobDescAiDialogHandler={hideJobDescAiDialogHandler}
                    AIServerStatus={AIServerStatus}
                    currentChat={aiDescType === 'IMPROVE' ? currentImproveChat.chat : currentChat}
                    updateChat={updateChat}
                    chatLoading={aiDescType === 'IMPROVE' ? currentImproveChat.loading : chatLoading}
                    onDeleteChat={onDeleteChat}
                    addDescriptionHandler={addDescriptionHandler}
                    addNewChat={aiDescType === 'IMPROVE' ? addNewImproveChat : addNewChat}
                    checkHealth={checkHealth}
                    jobState={jobState}
                    aiDescType={aiDescType}
                    setAiDescType={setAiDescType}
                />
                }
            </Modal >
        </div >
    )
}


function FieldLabel({ children, className }: {
    children: React.ReactNode
    className?: string
}) {
    return <div className={`text-neutralgray-8 text-base font-bold ${className}`}>
        {children}
    </div>
}

export default CreateJobForm