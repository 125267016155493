import React from 'react'

type Props = {
    className?: string
}

function Star({ className }: Props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" className={className}>
            <path d="M9.9986 16.5183L4.77766 19.2922C4.50487 19.4551 4.13357 19.4286 3.84941 19.2278C3.56904 19.0269 3.42886 18.6783 3.45159 18.3373L4.47835 12.4446L0.269396 8.26863C0.0246413 8.02989 -0.0621217 7.66989 0.0454796 7.34021C0.152702 7.01431 0.435724 6.77557 0.776335 6.72631L6.58112 5.8661L9.18022 0.512337C9.33556 0.198909 9.65003 0 9.9986 0C10.3472 0 10.6654 0.198947 10.817 0.512337L13.4161 5.8661L19.2243 6.72631C19.5653 6.77557 19.8456 7.01431 19.9555 7.34021C20.0616 7.66989 19.9744 8.02989 19.732 8.26863L15.5188 12.4446L16.5153 18.3373C16.5721 18.6783 16.4319 19.0269 16.1478 19.2278C15.8674 19.4286 15.462 19.4551 15.1892 19.2922L9.9986 16.5183ZM7.67229 6.39663C7.498 6.75663 7.15701 7.00673 6.75919 7.06736L1.55303 7.83663L5.33461 11.5806C5.61119 11.8573 5.74001 12.2551 5.6756 12.6455L4.78145 17.9318L9.42649 15.4497C9.78642 15.2564 10.2146 15.2564 10.5707 15.4497L15.2157 17.9318L14.3254 12.6455C14.2572 12.2551 14.386 11.8573 14.6664 11.5806L18.4476 7.83663L13.238 7.06736C12.8099 7.00673 12.4689 6.75663 12.3249 6.39663L9.9986 1.6037L7.67229 6.39663Z" fill="#5D9197" />
        </svg>
    )
}

export default Star