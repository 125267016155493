import React from 'react'

type Props = {
    className?: string
}

function Location({ className }: Props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="none"
            className={`fill-neutralgray-10 ${className}`}
        >
            <path d="M11.133 7.51946C11.133 9.2505 9.73097 10.6526 7.99993 10.6526C6.26889 10.6526 4.86682 9.2505 4.86682 7.51946C4.86682 5.78842 6.26889 4.38635 7.99993 4.38635C9.73097 4.38635 11.133 5.78842 11.133 7.51946ZM7.99993 9.39932C9.03777 9.39932 9.87979 8.5573 9.87979 7.51946C9.87979 6.48162 9.03777 5.63959 7.99993 5.63959C6.96209 5.63959 6.12006 6.48162 6.12006 7.51946C6.12006 8.5573 6.96209 9.39932 7.99993 9.39932ZM15.5194 7.51946C15.5194 10.9424 10.9372 17.0363 8.92811 19.5506C8.4464 20.1498 7.55346 20.1498 7.07174 19.5506C5.02739 17.0363 0.480469 10.9424 0.480469 7.51946C0.480469 3.36652 3.84699 0 7.99993 0C12.1513 0 15.5194 3.36652 15.5194 7.51946ZM7.99993 1.25324C4.53784 1.25324 1.73371 4.05737 1.73371 7.51946C1.73371 8.13041 1.94637 8.96852 2.38227 10.0024C2.80954 11.0168 3.40992 12.1173 4.08903 13.2178C5.42295 15.3836 7.00517 17.4553 7.99993 18.7047C8.99469 17.4553 10.5769 15.3836 11.9124 13.2178C12.5899 12.1173 13.1891 11.0168 13.616 10.0024C14.0547 8.96852 14.2661 8.13041 14.2661 7.51946C14.2661 4.05737 11.462 1.25324 7.99993 1.25324Z" />
        </svg>
    )
}

const LocationSolid = ({ className }: Props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="10" height="13" viewBox="0 0 10 13" fill="none" className={className}>
        <path d="M5.26878 12.3163C6.47424 10.8077 9.22355 7.15137 9.22355 5.09761C9.22355 2.60679 7.20269 0.585938 4.71187 0.585938C2.22105 0.585938 0.200195 2.60679 0.200195 5.09761C0.200195 7.15137 2.9495 10.8077 4.15496 12.3163C4.44399 12.6758 4.97975 12.6758 5.26878 12.3163ZM4.71187 3.59372C5.11073 3.59372 5.49325 3.75217 5.77528 4.0342C6.05732 4.31624 6.21576 4.69876 6.21576 5.09761C6.21576 5.49647 6.05732 5.87899 5.77528 6.16103C5.49325 6.44306 5.11073 6.6015 4.71187 6.6015C4.31301 6.6015 3.93049 6.44306 3.64846 6.16103C3.36642 5.87899 3.20798 5.49647 3.20798 5.09761C3.20798 4.69876 3.36642 4.31624 3.64846 4.0342C3.93049 3.75217 4.31301 3.59372 4.71187 3.59372Z" style={{ fillOpacity: 1 }} />
    </svg>
}

export { Location, LocationSolid }