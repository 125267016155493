import clsx from "clsx"
import React from 'react'

type Props = {
    className?: string
}
function Separator({ className }: Props) {

    const customClassName = className ? className.split(' ').map((item) => '!' + item).join(' ') : ''
    return <div className={clsx('h-[1px] bg-neutralgray-4 w-full', customClassName)} />
}
export default Separator