import dynamic from 'next/dynamic'
import React, { useEffect, useMemo, useState } from 'react'
import 'suneditor/dist/css/suneditor.min.css'

const SunEditor = dynamic(() => import('suneditor-react'), {
    ssr: false,
})

const JobDescription = ({ description, setProperty }) => {

    const [desc, setDesc] = useState(description)
    const [buttonList, setButtonList] = useState()

    const onDescriptionChange = (content) => {
        setDesc(content)
    }

    useEffect(() => {
        setProperty({ property: 'description', value: desc })
    }, [desc])

    useEffect(() => {
        import('suneditor-react').then(({ buttonList }) => {
            // Once buttonList is dynamically imported, set it to state
            setButtonList(buttonList);
        });
    }, []);

    const SunEditorWithLink = useMemo(() => {
        if (!buttonList) return <div></div>;
        return (
            <SunEditor
                setOptions={{
                    buttonList: [
                        ...buttonList.formatting,
                        ['link', 'list'] // Include the "list" button for bullet points
                    ],
                    defaultStyle: `font-family: var(--font-karla), sans-serif;font-size:14px`
                }}
                height="500px"
                onChange={onDescriptionChange}
                setContents={description}
                defaultValue={desc}
            />
        );
    }, [buttonList, description]);

    return SunEditorWithLink
}

export default JobDescription
