import React from 'react'

type Props = {
    className?: string
}

function Building({ className }: Props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" className={`${className} fill-neutralgray-10`}>
            <path d="M10.3086 0.0820312C10.1172 -0.0273438 9.88281 -0.0273438 9.69141 0.0820312L0.617188 5.22266C0.234375 5.44141 0 5.84375 0 6.28125C0 6.95312 0.546875 7.5 1.21875 7.5H10H18.7812C19.4531 7.5 20 6.95312 20 6.28125C20 5.84375 19.7656 5.4375 19.3828 5.22266L10.3086 0.0820312ZM11.9766 6.25C12.1133 5.96484 12.1875 5.64844 12.1875 5.3125C12.1875 4.10547 11.207 3.125 10 3.125C8.79297 3.125 7.8125 4.10547 7.8125 5.3125C7.8125 5.64844 7.88672 5.96484 8.02344 6.25H1.33984L10 1.34375L18.6602 6.25H11.9766ZM10 4.375C10.2486 4.375 10.4871 4.47377 10.6629 4.64959C10.8387 4.8254 10.9375 5.06386 10.9375 5.3125C10.9375 5.56114 10.8387 5.7996 10.6629 5.97541C10.4871 6.15123 10.2486 6.25 10 6.25C9.75136 6.25 9.5129 6.15123 9.33709 5.97541C9.16127 5.7996 9.0625 5.56114 9.0625 5.3125C9.0625 5.06386 9.16127 4.8254 9.33709 4.64959C9.5129 4.47377 9.75136 4.375 10 4.375ZM2.5 13.75C2.15625 13.75 1.875 14.0312 1.875 14.375C1.875 14.7188 2.15625 15 2.5 15H17.5C17.8438 15 18.125 14.7188 18.125 14.375C18.125 14.0312 17.8438 13.75 17.5 13.75V8.75H16.25V13.75H13.125V8.75H11.875V13.75H8.125V8.75H6.875V13.75H3.75V8.75H2.5V13.75ZM1.5625 16.25C1.21875 16.25 0.9375 16.5312 0.9375 16.875C0.9375 17.2188 1.21875 17.5 1.5625 17.5H18.4375C18.7812 17.5 19.0625 17.2188 19.0625 16.875C19.0625 16.5312 18.7812 16.25 18.4375 16.25H1.5625ZM0.625 18.75C0.28125 18.75 0 19.0312 0 19.375C0 19.7188 0.28125 20 0.625 20H19.375C19.7188 20 20 19.7188 20 19.375C20 19.0312 19.7188 18.75 19.375 18.75H0.625Z" />
        </svg>
    )
}

export default Building