"use client"
import Heading from "@/components/Common/Heading"
import React, { useEffect } from 'react'
import LoginForm from "./login-form"
import axios from "@/lib/axios"
import useSWR from "swr"
import { useRouter } from "next/navigation"



function Login({ submit, redirectionHandler, formState, resetStates }) {
    const { data: user, error } = useSWR('/user', () => axios.get('/user').then(res => res.data), { refreshInterval: 0 })
    const router = useRouter()
    useEffect(() => {
        if (user) {
            if (user?.passwordAdded) {
                router.push(`${process.env.NEXT_PUBLIC_ORG_URL}/welcome`)
            }
        }
    }, [user])

    return (
        <div className='login-main h-screen w-full flex items-center justify-center'>
            <div className='rounded-3xl h-max border border-product-moss-100 max-w-[76rem] w-[90%] shadow-login-container overflow-hidden flex items-stretch' >
                <section className="login-container md:block hidden w-1/2  h-full p-[0.5rem] md:p-[3.75rem]  ">
                    <div className="flex flex-col gap-5 flex-1">
                        <Heading size="h1" className=" !text-[2rem] leading-[3rem]">
                            Welcome to Abroadworks
                        </Heading>

                        <div className="select-none">
                            <img src='/images/client-setup.svg' className='h-auto' alt='client-setup' />
                        </div>
                    </div>
                </section>
                <section className="flex flex-1 bg-white py-[3.75rem] p-[1rem] md:px-[3.75rem] justify-center items-center">
                    <LoginForm submit={submit} redirectionHandler={redirectionHandler} formState={formState} resetStates={resetStates} />
                </section>
            </div>
        </div>
    )
}

export default Login


// linear-gradient(180deg, #E6FAF7 0%, rgba(230, 250, 247, 0.00) 100%);

