import React from 'react'

type Props = {
    className?: string
}

function Bookmark({ className }: Props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="none" className={className}>
            <path d="M13 0H3C1.62188 0 0.5 1.12109 0.5 2.5V19.3398C0.5 19.5681 0.624531 19.7785 0.824688 19.8883C1.02488 19.9969 1.26766 19.9884 1.46062 19.8675L8 15.707L14.5391 19.8672C14.6406 19.9688 14.7578 20 14.875 20C14.9788 20 15.0813 19.9744 15.1753 19.9231C15.375 19.8125 15.5 19.6016 15.5 19.3398V2.5C15.5 1.12109 14.3789 0 13 0ZM14.25 18.2383L8.33594 14.4742C8.19922 14.4062 8.11719 14.375 8 14.375C7.88281 14.375 7.76684 14.4073 7.6643 14.4727L1.75 18.2383V2.5C1.75 1.81094 2.31016 1.25 3 1.25H13C13.6898 1.25 14.25 1.81094 14.25 2.5V18.2383Z" fill="#5D9197" />
        </svg>
    )
}

export default Bookmark