import React from 'react'

type Props = {
    className?: string
}

function BriefCase({ className }: Props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" className={`fill-neutralgray-10 ${className}`}>
            <path d="M17.5 4.375H15V2.5C15 1.46602 14.1602 0.625 13.125 0.625H6.875C5.83984 0.625 5 1.46602 5 2.5V4.375H2.5C1.11914 4.375 0 5.49609 0 6.875V16.875C0 18.2559 1.11914 19.375 2.5 19.375H17.5C18.8809 19.375 20 18.2559 20 16.875V6.875C20 5.49609 18.8789 4.375 17.5 4.375ZM6.25 2.5C6.25 2.15508 6.53125 1.875 6.875 1.875H13.125C13.4688 1.875 13.75 2.15508 13.75 2.5V4.375H6.25V2.5ZM18.75 16.875C18.75 17.5641 18.1891 18.125 17.5 18.125H2.5C1.81094 18.125 1.25 17.5641 1.25 16.875V11.875H6.875V14.375C6.875 14.7205 7.15453 15 7.5 15H12.5C12.8455 15 13.125 14.7205 13.125 14.375V11.875H18.75V16.875ZM8.125 13.75V11.875H11.875V13.75H8.125ZM18.75 10.625H1.25V6.875C1.25 6.18594 1.81094 5.625 2.5 5.625H17.5C18.1891 5.625 18.75 6.18594 18.75 6.875V10.625Z" />
        </svg>
    )
}

export default BriefCase