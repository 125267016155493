import React from 'react'

type Props = {
    className?: string
}

function Suitcase({ className }: Props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" className={className}>
            <path d="M6.25 2.5V4.375H13.75V2.5C13.75 2.15625 13.4688 1.875 13.125 1.875H6.875C6.53125 1.875 6.25 2.15625 6.25 2.5ZM5 4.375V2.5C5 1.46484 5.83984 0.625 6.875 0.625H13.125C14.1602 0.625 15 1.46484 15 2.5V4.375H17.5C18.8789 4.375 20 5.49609 20 6.875V16.875C20 18.2539 18.8789 19.375 17.5 19.375H2.5C1.12109 19.375 0 18.2539 0 16.875V6.875C0 5.49609 1.12109 4.375 2.5 4.375H5ZM14.375 5.625H5.625H5V18.125H15V5.625H14.375ZM2.5 5.625C1.80859 5.625 1.25 6.18359 1.25 6.875V16.875C1.25 17.5664 1.80859 18.125 2.5 18.125H3.75V5.625H2.5ZM17.5 18.125C18.1914 18.125 18.75 17.5664 18.75 16.875V6.875C18.75 6.18359 18.1914 5.625 17.5 5.625H16.25V18.125H17.5Z" fill="#5D9197" />
        </svg>
    )
}

export default Suitcase