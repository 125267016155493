import { useState } from 'react'
import { useSWRConfig } from 'swr'
import axios from '@/lib/axios'

interface User {
    id: string
}

interface ResponseData {
    data: any[]
}

import useSWR from 'swr'

const UseAfterLogin = (endpoint: string) => {
    const { data, mutate } = useSWR(endpoint, async () => {
        const res = await axios.get<ResponseData>(endpoint)
        return res.data.data
    })

    async function getAfterLoginData(user: User): Promise<any[]> {
        if (!user) return []

        try {
            const res = await axios.get<ResponseData>(endpoint)
            mutate(res.data.data, false) // Update cache without revalidation
            return res.data.data
        } catch (error) {
            console.error('Error fetching data:', error)
            return []
        }
    }

    return { getAfterLoginData, data }
}


export default UseAfterLogin
