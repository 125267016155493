import React from 'react'
import HealthCard from "./health-card";
import JobForm from "./form";
import ChatComponent from "./chat";
import Heading from "@/components/Common/Heading";
import { X } from "phosphor-react";
import { Suitcase } from "@aw/icons";
import ImproveJobDesc from "./improve-job-desc";
import JobImproveForm from "./improve-form";
import { ChatType } from "../../create-job-form";
type Props = {
    initialRef: any;
    hideJobDescAiDialogHandler: () => void;
    AIServerStatus: any;
    currentChat: any;
    updateChat: ({ userMessage, type }: { userMessage: string; type: ChatType }) => void;
    chatLoading: boolean;
    addDescriptionHandler: any;
    addNewChat: any;
    checkHealth: any;
    onDeleteChat: any;
    jobState: any;
    aiDescType: null | ChatType;
    setAiDescType: React.Dispatch<React.SetStateAction<"GEN" | "IMPROVE" | null>>
}



function JobRecommendationDialog({ onDeleteChat, initialRef, hideJobDescAiDialogHandler, AIServerStatus, currentChat, updateChat, chatLoading, addDescriptionHandler, addNewChat, checkHealth, jobState, aiDescType, setAiDescType }: Props) {

    return (
        <div className="relative flex h-max w-full flex-col gap-6 rounded-[0.75rem]  bg-white py-6 text-sm " id='address-information-modal'
            ref={initialRef}
        >
            <div className="absolute top-4 right-4">
                <button type="button" onClick={() => {
                    hideJobDescAiDialogHandler();
                    setAiDescType(null);
                }} className="relative flex items-center justify-center group z-[0] h-10 w-10">
                    <div className="h-6 w-6 absolute top-1/2 z-[-1] left-1/2 -translate-x-1/2 -translate-y-1/2 group-hover:bg-error-50 rounded-sm scale-0 group-hover:scale-100 transition ease">
                    </div>
                    <X className="h-4 w-4 z-[1] group-hover:text-error-500 fill-neutralgray-10"></X>
                </button>
            </div>
            {/* Header  */}
            <div className="flex items-center gap-4 px-6">
                <div className="rounded-md bg-neutralgray-3 p-2">
                    <Suitcase className="w-6 h-6 fill-neutralgray-10" />
                </div>
                <Heading size="h4">{aiDescType === 'IMPROVE' ? "Improve" : "Generate"} Job Description</Heading>
            </div>
            <div className="w-full flex flex-1 flex-col h-full">
                {(AIServerStatus.loading || !AIServerStatus.success) ? <HealthCard
                    {...AIServerStatus}
                    onRetry={checkHealth} />
                    : <>
                        {
                            aiDescType === "IMPROVE" && (!currentChat ? <JobImproveForm
                                description={jobState.description}
                                addDescriptionHandler={addDescriptionHandler}
                            /> : <ChatComponent
                                currentChat={currentChat}
                                onDeleteChat={onDeleteChat}
                                updateChat={updateChat}
                                addDescriptionHandler={addDescriptionHandler}
                                loading={chatLoading}
                                type={aiDescType}
                            />)
                        }

                        {
                            aiDescType === "GEN" && (!currentChat ? <JobForm
                                jobTitle={jobState?.title}
                                addNewChat={addNewChat}
                                loading={chatLoading}
                            /> : <ChatComponent
                                currentChat={currentChat}
                                onDeleteChat={onDeleteChat}
                                updateChat={updateChat}
                                addDescriptionHandler={addDescriptionHandler}
                                loading={chatLoading}
                                type={aiDescType}
                            />)
                        }
                    </>
                }
            </div>
        </div>
    )
}

export default JobRecommendationDialog