import React, { ChangeEvent, useState } from 'react'
import { Info, TrashSimple } from 'phosphor-react'
import toast from 'react-hot-toast'

import { Popover } from 'antd'
import { PulseLoader } from 'react-spinners'
import Button from "@/components/Common/Button"
import { Input, Label, TextArea } from "@aw/design-system"
type Props = {
    jobTitle: string,
    addNewChat: (chat: JOB_DESCRIPTION) => void,
    loading: boolean,
}

export type JOB_DESCRIPTION = {
    "role": string,
    "requirements": string[],
    "additional_context": { [key: string]: string }
}


function JobForm({ jobTitle, addNewChat, loading }: Props) {

    const [state, setState] = useState<JOB_DESCRIPTION>({
        "role": jobTitle ?? "",
        "requirements": [],
        "additional_context": {}
    })

    const [additionalContext, setAdditionalContext] = useState<{ [key: string]: string }>({
        key: '',
        value: ''
    })

    function onChangeRole(e: ChangeEvent<HTMLInputElement>) {
        setState({ ...state, role: e.target.value })
    }

    function onChangeRequirements(e: ChangeEvent<HTMLInputElement>) {
        setState({ ...state, requirements: e.target.value.split(',') })
    }

    function onAddAdditionalContext() {
        if (!additionalContext?.key || !additionalContext?.value) {
            if (!additionalContext?.key) {
                toast.error('Key is required')
                return
            }
            if (!additionalContext?.value) {
                toast.error('Value is required')
                return
            }
        }
        setAdditionalContext({
            key: '',
            value: ''
        })
        setState({
            ...state, additional_context: {
                ...state.additional_context,
                [additionalContext?.key]: additionalContext?.value
            }
        })
    }


    function onChangeAdditionalContext(e: ChangeEvent<HTMLInputElement>) {
        setAdditionalContext({
            ...additionalContext,
            [e.target.name]: e.target.value
        })
    }

    function onSubmit() {
        if (!state.role) {
            toast.error('Role is required')
            return
        }
        if (state.requirements.length === 0) {
            toast.error('Requirements are required')
            return
        }
        addNewChat({ ...state })
    }


    function onDeleteAdditionalContext(key: string) {
        const context = JSON.parse(JSON.stringify(state.additional_context))
        delete context[key]
        setState({
            ...state,
            additional_context: context
        })
    }

    return (
        <div className="flex px-6 h-full overflow-y-auto scrollbar min-h-[20vh] min-w-[20vw] flex-col gap-6 rounded-[0.75rem] bg-white text-sm" id='address-information-modal'>
            <div className='flex flex-col gap-3'>
                <div className='flex flex-col gap-2'>
                    <Label
                        required={true}
                    >
                        Role
                    </Label>
                    <Input placeholder='Role' value={state?.role} onChange={onChangeRole} />
                </div>
                <div className='flex flex-col gap-2'>

                    <div className='flex  items-center gap-2 '>
                        <Label
                            required={true}
                        >
                            Requirements
                        </Label>
                        <Popover
                            content={
                                <div className='text-xs max-w-[12rem]'>{
                                    `You can add skills, experience, or any other requirements that you want to include in the job description, e.g. 'Bookkeeping, MS-Excel'`
                                }

                                </div>
                            }
                        >
                            <Info className='h-4 w-4 text-neutralgray-5' />
                        </Popover>
                    </div>

                    <TextArea
                        placeholder='Please enter requirements separated by commas ( , )'
                        value={state?.requirements.join(',')}
                        onChange={onChangeRequirements}
                        rows={4}
                        className="placeholder:text-[#9498A2] text-sm text-neutralgray-9 py-2 px-4"
                    />
                </div>
                {/* <div className='flex flex-col gap-2'>
                    <Label>
                        Additional Requirement
                    </Label>
                    <div className='flex items-center gap-2'>
                        <Input placeholder='Key' name='key'
                            value={additionalContext?.key}
                            onChange={onChangeAdditionalContext} />
                        <Input placeholder='Value'
                            value={additionalContext?.value}
                            name='value' onChange={onChangeAdditionalContext} />
                        <Button
                            color='primary'
                            onClick={onAddAdditionalContext}
                        >
                            Add
                        </Button>
                    </div>
                    {
                        Object.keys(state?.additional_context).map((key) => (
                            <div className='flex items-center gap-2' key={key}>
                                <div className='w-full border px-4 py-2 rounded-md border-neutralgray-3 '>
                                    {key}
                                </div>
                                <div className='w-full  px-4 py-2 rounded-md bg-neutralgray-2'>


                                    {state.additional_context[key]}
                                </div>
                                <div className='w-28 '>

                                    <div className='h-6 w-6 flex items-center justify-center rounded-md hover:bg-[whitesmoke] cursor-pointer'
                                        onClick={() => {
                                            onDeleteAdditionalContext(key)
                                        }}
                                    >
                                        <TrashSimple className='h-4 w-4 text-error-500' />
                                    </div>
                                </div>

                            </div>
                        ))
                    }
                </div> */}
            </div>
            <div className='flex w-full mb-4'>
                <Button className='!w-full'
                    color='primary'
                    loading={loading}
                    loadingIcon={<PulseLoader color='white' size={4} />}
                    loadingText='Generating'
                    onClick={onSubmit}
                >
                    Generate
                </Button>
            </div>
        </div>


    )
}

export default JobForm
