import React from 'react'

type Props = {
    className?: string
}
function Calendar({ className }: Props) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path
                className={className}
                d="M5.625 0C5.96875 0 6.25 0.28125 6.25 0.625V2.5H13.75V0.625C13.75 0.28125 14.0312 0 14.375 0C14.7188 0 15 0.28125 15 0.625V2.5H16.25C17.6289 2.5 18.75 3.62109 18.75 5V6.25V7.5V17.5C18.75 18.8789 17.6289 20 16.25 20H3.75C2.37109 20 1.25 18.8789 1.25 17.5V7.5V6.25V5C1.25 3.62109 2.37109 2.5 3.75 2.5H5V0.625C5 0.28125 5.28125 0 5.625 0ZM17.5 7.5H2.5V17.5C2.5 18.1914 3.05859 18.75 3.75 18.75H16.25C16.9414 18.75 17.5 18.1914 17.5 17.5V7.5ZM16.25 3.75H3.75C3.05859 3.75 2.5 4.30859 2.5 5V6.25H17.5V5C17.5 4.30859 16.9414 3.75 16.25 3.75Z"

            />
        </svg>
    )
}

export default Calendar
