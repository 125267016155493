import React, { ChangeEvent, useState } from 'react'
import { Info, TrashSimple } from 'phosphor-react'
import toast from 'react-hot-toast'

import { Popover } from 'antd'
import { PulseLoader } from 'react-spinners'
import Button from "@/components/Common/Button"
import { Input, Label, TextArea } from "@aw/design-system"
import { useJobsAI } from "@/context/ai/jobs"

type Props = {
    description: string,
}

function JobImproveForm({ description }: Props) {

    const { addNewImproveChat, currentImproveChat } = useJobsAI();


    const [state, setState] = useState<{
        modifications: string,
    }>({
        modifications: "",
    })


    function onSubmit() {
        setState(prevState => ({ ...prevState, showChat: true }));
        addNewImproveChat({
            existing_description: description,
            modifications: state.modifications
        })
    }


    function onChangeHandler(e: ChangeEvent<HTMLInputElement>) {
        setState({ ...state, modifications: e.target.value })
    }

    return (
        <div className="flex px-6 h-full overflow-y-auto scrollbar min-h-[20vh] min-w-[20vw] flex-col gap-6 rounded-[0.75rem] bg-white text-sm" id='address-information-modal'>
            <div className='flex flex-col gap-3'>
                <div className='flex flex-col gap-2'>
                    <Label
                        required={false}
                    >
                        Modifications
                    </Label>
                    <Input placeholder='Enter new modifications' value={state.modifications} onChange={onChangeHandler} />
                </div>
            </div>
            <div className='flex w-full'>
                <Button className='!w-full'
                    color='primary'
                    loading={currentImproveChat.loading}
                    loadingIcon={<PulseLoader color='white' size={4} />}
                    loadingText='Improving'
                    onClick={onSubmit}
                >
                    Improve Description
                </Button>
            </div>
        </div>


    )
}

export default JobImproveForm
