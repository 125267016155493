import { fetcher } from "@aw/lib";
import useSwr from 'swr/immutable';

type model = 'job_types' | 'experience_levels' | 'countries' | `states?country_id=${string}` | `cities?state_id=${string}` | `available_job_fields`



const getData = <T>(API: `/metadata/index?model=${model}` | `/metadata/${model}` | null) => {

    const swr = useSwr<T>(API, fetcher);

    return swr;
}

type prop = {
    "id": number,
    "name": string
}[]

export const getJobTypesData = () => getData<prop>('/metadata/index?model=job_types')

export const getExperienceLevelsData = () => getData<prop>('/metadata/index?model=experience_levels')

export const getCountriesData = () => getData<prop>('/metadata/index?model=countries')

export const getStateData = (cid: number) => getData<prop>(cid ? `/metadata/states?country_id=${cid}` : null)

export const getCityData = (cid: number) => getData<prop>(cid ? `/metadata/cities?state_id=${cid}` : null)

export const getJobFieldsData = () => getData<prop>('/metadata/index?model=available_job_fields')