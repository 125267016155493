import Button from "@/components/Common/Button";
import Heading from "@/components/Common/Heading";
import React from 'react';

interface props {
    loading: boolean;
    onRetry: () => void;
    success: boolean;
}

const HealthCard = ({ loading, onRetry, success }: props) => {
    return (
        <div className='px-6 w-full'>
            {loading ? (
                <div className="flex h-32 gap-3 fade-in-25 md:gap-6 w-full pt-4">
                    <div className="flex w-full max-w-3xl flex-col gap-4 rounded-lg pt-2">
                        <div
                            className="h-4 w-10/12 origin-left animate-loading bg-[length:200%] rounded-full bg-gradient-to-r from-blue-50 from-30% via-blue-600/60 to-blue-50 bg-2x opacity-0"
                        ></div>
                        <div
                            className="h-4 w-full origin-left animate-loading bg-[length:200%] rounded-full bg-gradient-to-r from-blue-500/60 via-moss-600 via-30% to-blue-500/60 to-60% bg-2x opacity-0 "
                        ></div>
                        <div
                            className="duration-600 h-4 w-3/5 origin-left animate-loading bg-[length:200%] rounded-full bg-gradient-to-r from-blue-50 from-40% via-blue-500/60 to-blue-50 to-70% bg-2x opacity-0 "
                        ></div>
                    </div>
                </div>
            ) : (
                !success ? (
                    <div className="flex flex-1 flex-col gap-4 items-center justify-center">
                        <img src="/images/oops.svg" alt="error" />
                        <Heading size="h3">
                            Oops! The AI server took a coffee break ☕.
                        </Heading>
                        <div className="text-neutralgray-7 max-w-[30rem] text-center">
                            We're having trouble connecting to the AI server at the moment. This might take a little time to resolve. Please wait patiently, or click the retry button to attempt reconnecting.
                        </div>
                        <Button
                            onClick={onRetry}
                            color="primary"
                            className="mt-4"
                        >
                            Retry
                        </Button>
                    </div>
                ) : (
                    <></>
                )
            )}
        </div>
    );
};

export default HealthCard;
