import useTheme from '../../aw-hooks/src/useTheme';

import clsx from 'clsx';
import { WarningCircle } from 'phosphor-react';
import React from "react";

const TextArea = ({ disabled = false, error = false, left = null, right = null, className = '', ...props }, ref) => {

    const { theme } = useTheme()
    const defaultClasses =
        'p-2 block w-full focus:outline-none rounded-[0.625rem] shadow-sm border-[#9498A2] focus:ring-opacity-50 placeholder:text-sm placeholder:italic placeholder:text-slate-400 text-base h-24'
    const errorClasses = 'pr-10 border-red-300 text-red-900 placeholder-red-300  focus:ring-red-500 focus:border-red-500'

    const themeClass = 'focus:border-indigo-300 focus:ring-indigo-200'

    return (
        <div className={clsx((left || right) && 'flex', 'relative mt-1 border shadow-sm rounded-[0.625rem]', props.blur ? 'blur-sm' : 'blur-none')}>
            {left}
            <textarea spellCheck='false' disabled={disabled} className={`${props.value ? '!bg-neutralgray-2' : 'bg-white'} ${className} ${clsx(error && errorClasses)} ${themeClass} ${defaultClasses}`} {...props} ref={ref} />
            {right}

            {error && (
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <WarningCircle className="h-5 w-5 text-red-500" weight="fill" />
                </div>
            )}
        </div>
    )
}

export default React.forwardRef(TextArea)
