'use client';

import { _themed } from '@aw/utils';
import React, { Dispatch, ReactNode, useEffect, useState } from 'react';
import { useAuth } from '@/hooks/auth';
import UseAfterLogin from '@/hooks/useAfterLogin';
import axios from '@/lib/axios';
import { OrganizationData, organizations } from '@/data/organizations';

interface WorkSpaceContextInterface {
    organizations: any[];
    currentOrganization: string | number | null;
    setCurrentOrganization: Dispatch<React.SetStateAction<string | null | number>>;
    userPermissions: string[];
    getOrganizationData: () => OrganizationData | undefined;
}

const WorkSpaceContext = React.createContext<WorkSpaceContextInterface>({
    organizations: [],
    currentOrganization: null,
    setCurrentOrganization: () => null,
    userPermissions: [],
    getOrganizationData: () => undefined,
});

interface WorkSpaceProviderProps {
    children: ReactNode; // ReactNode ensures the children prop works correctly
}

const WorkSpaceProvider: React.FC<WorkSpaceProviderProps> = ({ children }) => {
    const { user } = useAuth();

    const fetcher = (url: string) => axios.get(url).then((res) => res.data);

    const [currentOrganization, setCurrentOrganization] = useState<string | number | null>(null);

    const { getAfterLoginData, data } = UseAfterLogin('/user/organizations');

    const [userPermissions, setUserPermissions] = useState<string[]>([]);

    const getPermissions = async () => {
        if (!currentOrganization || !user?.id) return;
        const res = await axios.get(`/organization/${currentOrganization}/user/${user.id}/permissions`);
        setUserPermissions(res.data.data);
    };

    useEffect(() => {
        if (!user) return;

        (async () => {
            await getAfterLoginData(user);
        })();
    }, [user, getAfterLoginData]);

    useEffect(() => {
        if (!currentOrganization) return;
        getPermissions();
    }, [currentOrganization]);

    useEffect(() => {
        if (typeof window === 'undefined' || !data?.length || !user) return;

        const storedOrg = localStorage.getItem(`${user.id}organization`);
        if (!storedOrg) {
            if (Array.isArray(data) && data.length > 0) {
                const defaultOrg = data[0].uid;
                localStorage.setItem(`${user.id}organization`, defaultOrg);
                setCurrentOrganization(defaultOrg);
            }
        } else {
            setCurrentOrganization(storedOrg);
        }
    }, [data, user]);

    const getOrganizationData = (): OrganizationData | undefined => {
        return organizations[currentOrganization as string] || undefined;
    };

    return (
        <WorkSpaceContext.Provider
            value={{
                organizations: data || [],
                currentOrganization,
                setCurrentOrganization,
                userPermissions,
                getOrganizationData,
            }}
        >
            {children}
        </WorkSpaceContext.Provider>
    );
};

export { WorkSpaceProvider, WorkSpaceContext };
