import React from 'react'

type Props = {
    className?: string
}

function MoneyBill({ className }: Props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14" fill="none" className={`fill-[#5D9197] ${className}`}>
            <path d="M13.3333 7.00065C13.3333 8.84093 11.8403 10.334 10 10.334C8.12847 10.334 6.66667 8.84093 6.66667 7.00065C6.66667 5.12912 8.12847 3.66732 10 3.66732C11.8403 3.66732 13.3333 5.12912 13.3333 7.00065ZM10 4.77843C8.77431 4.77843 7.77778 5.77496 7.77778 7.00065C7.77778 8.22635 8.77431 9.22287 10 9.22287C11.2257 9.22287 12.2222 8.22635 12.2222 7.00065C12.2222 5.77496 11.2257 4.77843 10 4.77843ZM0 2.55621C0 1.32878 0.994792 0.333984 2.22222 0.333984H17.7778C19.0035 0.333984 20 1.32878 20 2.55621V11.4451C20 12.6708 19.0035 13.6673 17.7778 13.6673H2.22222C0.994792 13.6673 0 12.6708 0 11.4451V2.55621ZM18.8889 3.66732V2.55621C18.8889 1.94162 18.3924 1.4451 17.7778 1.4451H16.6667C16.6667 2.67079 17.6632 3.66732 18.8889 3.66732ZM15.5556 1.4451H4.44444C4.44444 3.28537 2.95208 4.77843 1.11111 4.77843V9.22287C2.95208 9.22287 4.44444 10.6847 4.44444 12.5562H15.5556C15.5556 10.6847 17.0174 9.22287 18.8889 9.22287V4.77843C17.0174 4.77843 15.5556 3.28537 15.5556 1.4451ZM2.22222 12.5562H3.33333C3.33333 11.3305 2.33854 10.334 1.11111 10.334V11.4451C1.11111 12.0597 1.60868 12.5562 2.22222 12.5562ZM18.8889 10.334C17.6632 10.334 16.6667 11.3305 16.6667 12.5562H17.7778C18.3924 12.5562 18.8889 12.0597 18.8889 11.4451V10.334ZM1.11111 2.55621V3.66732C2.33854 3.66732 3.33333 2.67079 3.33333 1.4451H2.22222C1.60868 1.4451 1.11111 1.94162 1.11111 2.55621Z" />
        </svg>
    )
}

export default MoneyBill