import { configureStore } from '@reduxjs/toolkit'
import remarks from './../modules/organization/candidate/slice/remarks'
import job from '@/modules/organization/jobs/listings/slice/job'
import adjustmentSlice from '@/modules/organization/manage-payments/slice/adjustmentSlice'

const store = configureStore({
    reducer: {
        job: job,
        adjustmentSlice: adjustmentSlice,
        remarks: remarks,
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
