import React from 'react'

type Props = {
    className?: string
}

function ArrowRight({ className }: Props) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none" className={`${className} fill-neutralgray-10`}>
        <path d="M7.8683 4.36199C7.95223 4.26827 8.00045 4.13707 8.00045 3.99963C8.00045 3.86218 7.95223 3.73307 7.8683 3.63727L4.72526 0.138637C4.55383 -0.0529548 4.28238 -0.0446246 4.11987 0.155297C3.95736 0.355219 3.96272 0.671762 4.13416 0.861272L6.50215 3.49982H0.428595C0.191082 3.49982 0 3.72265 0 3.99963C0 4.2766 0.191082 4.49943 0.428595 4.49943H6.50215L4.13237 7.1359C3.96093 7.3275 3.95558 7.64196 4.11809 7.84188C4.2806 8.0418 4.55204 8.04805 4.72348 7.85854L7.86651 4.3599L7.8683 4.36199Z" />
    </svg>)
}

export default ArrowRight